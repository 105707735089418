<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-card class="mt-2">
      <v-row justify="center">
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          color="primary"
          @click="toTop"
        >
          <!-- <v-icon>keyboard_arrow_up</v-icon> -->
          위로
        </v-btn>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-card>
          <v-row>
            <v-col class="d-flex" cols="6">
              <v-autocomplete
                class="ml-2"
                :items="items"
                v-model="myMBTI"
                label="나의 MBTI"
                outlined
                chips
                deletable-chips
                @change="myItemChange"
              ></v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="6">
              <v-autocomplete
                class="ml-2"
                :items="items"
                v-model="otherMBTI"
                label="상대방 MBTI"
                outlined
                chips
                deletable-chips
                @change="otherItemChange"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="myMBTI" class="d-flex" cols="6">
              <v-card-text>
                <v-row align="center" class="mx-0"></v-row>
                <div class="my-4 text-h5">특징</div>
                <div>{{ getTextByMbti(myMBTI) }}</div>
              </v-card-text>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="otherMBTI" class="d-flex" cols="6">
              <v-card-text>
                <v-row align="center" class="mx-0"></v-row>
                <div class="my-4 text-h5">특징</div>
                <div>{{ getTextByMbti(otherMBTI) }}</div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              :disabled="isActiveResult(myMBTI, otherMBTI)"
              :loading="dialog"
              class="white--text mb-2 auto"
              color="indigo darken-1"
              @click="dialog = true"
              >결과 보기</v-btn
            >
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
              <v-card color="indigo darken-1" dark>
                <v-card-text>
                  궁합을 보는 중입니다..
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-card>
          <v-row class="text-center">
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-img
                class="mx-3 mt-3"
                :src="require('@/assets/btn-share-kakao.png')"
                :disabled="isActiveResult(myMBTI, otherMBTI)"
                contain
                style="cursor: pointer"
                @click="shareKakao"
              ></v-img>
            </v-col>
            <v-col cols="2">
              <v-img
                class="mx-3 mt-3"
                :src="require('@/assets/btn-share-facebook.png')"
                :disabled="isActiveResult(myMBTI, otherMBTI)"
                contain
                style="cursor: pointer"
                @click="shareFacebook"
              ></v-img>
            </v-col>
            <v-col cols="2">
              <v-img
                class="mx-3 mt-3"
                :src="require('@/assets/btn-share-twitter.png')"
                :disabled="isActiveResult(myMBTI, otherMBTI)"
                contain
                style="cursor: pointer"
                @click="shareTwitter"
              ></v-img>
            </v-col>
            <v-col cols="2">
              <v-img
                class="mx-3 mt-3"
                :src="require('@/assets/btn-share-linkcopy.png')"
                :disabled="isActiveResult(myMBTI, otherMBTI)"
                contain
                @click="copyURL"
                style="cursor: pointer"
              ></v-img>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row justify="center" class="mt-3">
              <div class="font-weight-bold my-4 text-h2">
                {{ this.resultPercent }}%
              </div>
              <div>
                <v-dialog v-model="resultBestWorstDialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-3 mt-7"
                      text
                      icon
                      color="pink lighten-3"
                      v-bind="attrs"
                      v-on="on"
                      dark
                    >
                      <v-icon size="45">mdi-chart-bar</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="body-1">
                      나의 {{ this.myMBTI }}'s Best/Worst 타입
                      <v-spacer></v-spacer>
                      <v-btn icon @click="resultBestWorstDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="subbody-2 font-weight-bold">
                      Best: {{ this.myBestMBTI }} <br />
                      Worst: {{ this.myWorstMBTI }} <br />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row justify="center">
              <div class="font-weight-medium" v-if="resultPercent == 100">
                이보다 완벽할 수 없다!! 천생연분!!
              </div>
              <div class="font-weight-medium" v-if="resultPercent == 75">
                아주 좋은 관계가 될수 있어요!!
              </div>
              <div class="font-weight-medium" v-if="resultPercent == 50">
                양념반 후라이드반 딱 반반!!
              </div>
              <div class="font-weight-medium" v-if="resultPercent == 25">
                최악은 아니지만....
              </div>
              <div class="font-weight-medium" v-if="resultPercent == 0">
                진짜 궁합 최악! 지구 멸망의 길
              </div>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12">
                <v-img
                  v-if="resultPercent == 100"
                  src="@/assets/100.png"
                ></v-img>
                <v-img v-if="resultPercent == 75" src="@/assets/75.png"></v-img>
                <v-img v-if="resultPercent == 50" src="@/assets/50.png"></v-img>
                <v-img v-if="resultPercent == 25" src="@/assets/25.png"></v-img>
                <v-img v-if="resultPercent == 0" src="@/assets/0.png"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
let baseURL = "https://mbting.conpurple.com";

export default {
  name: "MbtiMatch",
  data: () => ({
    dialog: false,
    resultBestWorstDialog: false,
    result: false,
    resultPercent: "0",
    resultText: "",
    isActive: false,
    enabledShare: false,
    fab: false,
    items: [
      "INFP",
      "ENFP",
      "INFJ",
      "ENFJ",
      "INTJ",
      "ENTJ",
      "INTP",
      "ENTP",
      "ISFP",
      "ESFP",
      "ISTP",
      "ESTP",
      "ISFJ",
      "ESFJ",
      "ISTJ",
      "ESTJ",
    ],
    beforeMyMBTI: "",
    myMBTI: "",
    beforeOtherMBTI: "",
    otherMBTI: "",
    myBestMBTI: "",
    myWorstMBTI: "",
    links: [
      { link: "/match", title: "궁합 보기" },
      { link: "/board", title: "게시판" },
    ],
  }),
  watch: {
    dialog(val) {
      if (!val) return;

      this.result = false;
      setTimeout(
        () => (
          (this.dialog = false),
          (this.result = true),
          this.getPercent(this.myMBTI, this.otherMBTI),
          (this.enabledShare = true)
        ),
        1300
      );
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
    // this.$router.push('/match').catch(() => {});
    if (Object.keys(this.$route.params).length > 0) {
      this.myMBTI = this.$route.params.my;
      this.myItemChange(this.myMBTI);
      this.otherMBTI = this.$route.params.other;
      this.otherItemChange(this.otherMBTI);
      this.dialog = true;
    }
  },
  methods: {
    isActiveResult: function (my, other) {
      if (my == null || other == null) {
        this.isActive = true;
        return this.isActive;
      }
      if (my != "" && other != "") {
        this.isActive = false;
        return this.isActive;
      } else {
        this.isActive = true;
        return this.isActive;
      }
    },
    myItemChange: function (e) {
      if (e == null || this.beforeMyMBTI != e) {
        this.beforeMyMBTI = e;
        this.result = false;
        this.enabledShare = false;
        this.isActiveResult(e, e);
        return;
      }
      this.beforeMyMBTI = e;
    },
    otherItemChange: function (e) {
      if (e == null || this.beforeOtherMBTI != e) {
        this.beforeOtherMBTI = e;
        this.result = false;
        this.enabledShare = false;
        this.isActiveResult(e, e);
        return;
      }
      this.beforeOtherMBTI = e;
    },
    getTextByMbti: function (type) {
      var text = "";
      if (type == "ISTJ") {
        text = "체계적으로 자신들의 일, 가정, 삶을 구성해 나갈 때 기쁨을 얻음.";
      } else if (type == "ISFJ") {
        text =
          "자신에게 중요한 사람들의 느낌에 관심이 많고, 그들과 관련된 구체적인 것을 잘 알아차리고 기억함.";
      } else if (type == "INFJ") {
        text = "자신의 비전을 수행하기 위해 사람들을 조직화하고 동기화 시킴.";
      } else if (type == "INTJ") {
        text =
          "회의적이고 독립적이며, 자신과 타인들에 대한 능력과 수행에 높은 기준을 지니고 있음.";
      } else if (type == "ISTP") {
        text =
          "원인과 결과에 관심이 많고, 사실을 논리적으로 구조화 하며, 효율성에 가치를 둠.";
      } else if (type == "ISFP") {
        text =
          "자신의 가치를 중요시 여기며, 의미 있는 사람들에게 충실하며, 헌신적임.";
      } else if (type == "INFP") {
        text =
          "자신들의 가치가 위협받지 않는 한 잘 적응하고, 융통성이 있으며, 수용하는 편임.";
      } else if (type == "INTP") {
        text =
          "이론적이고, 추상적이며, 자신의 관심 영역에 논리적이고 이론적인 설명을 하고자 함.";
      } else if (type == "ESTP") {
        text =
          "지금 이 순간에서 벌어지는 일에 관심이 많고, 타인들과 활기차게 할 수 있는 매 순간을 즐김.";
      } else if (type == "ESFP") {
        text =
          "타인들과 함께 일하는 것을 즐미며, 상싱적, 현실적인 접근으로 일을 재미있게 하고자 함.";
      } else if (type == "ENFP") {
        text =
          "자발적이고, 융통성이 있으며, 열정적이며, 때로 자신만의 즉흥적, 유창한 언변을 발휘함.";
      } else if (type == "ENTP") {
        text =
          "새롭고 도전적인 문제를 해결하는데 흥미를 느끼며, 개념적 가능성을 창출함.";
      } else if (type == "ESTJ") {
        text =
          "사람들을 조직하며, 가능한 가장 효율적인 방법으로 결과를 얻는 것에 초점을 맞춤.";
      } else if (type == "ESFJ") {
        text =
          "사소한 일들도 성실하게 끝까지 해내며, 자신의 존재와 기여를 인정받기 원함.";
      } else if (type == "ENFJ") {
        text = "비전과 목표에 대해서 사람들을 동기화시키는 리더쉽을 발휘함.";
      } else if (type == "ENTJ") {
        text =
          "자신들의 지식을 확장하고자 하고, 또한 그것을 타인들에게 전달하는 것을 즐김.";
      }
      return text;
    },
    getPercent: function (my, ot) {
      this.getBestWorstMbtiType(my, "best");
      this.getBestWorstMbtiType(my, "worst");
      if (my == "INFP") {
        if (ot == "ENFJ" || ot == "ENTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INFP" ||
          ot == "ENFP" ||
          ot == "INFJ" ||
          ot == "INTJ" ||
          ot == "INTP" ||
          ot == "ENTP"
        ) {
          this.resultPercent = "75";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ENFP") {
        if (ot == "INFJ" || ot == "INTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INFP" ||
          ot == "ENFP" ||
          ot == "ENFJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP"
        ) {
          this.resultPercent = "75";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "INFJ") {
        if (ot == "ENFP" || ot == "ENTP") {
          this.resultPercent = "100";
        } else if (
          ot == "INFP" ||
          ot == "INFJ" ||
          ot == "ENFJ" ||
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP"
        ) {
          this.resultPercent = "75";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ENFJ") {
        if (ot == "INFP" || ot == "ISFP") {
          this.resultPercent = "100";
        } else if (
          ot == "ENFP" ||
          ot == "INFJ" ||
          ot == "ENFJ" ||
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP"
        ) {
          this.resultPercent = "75";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "INTJ") {
        if (ot == "ENFP" || ot == "ENTP") {
          this.resultPercent = "100";
        } else if (
          ot == "INFP" ||
          ot == "INFJ" ||
          ot == "ENFJ" ||
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP"
        ) {
          this.resultPercent = "75";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "50";
        } else if (
          ot == "ISFJ" ||
          ot == "ESFJ" ||
          ot == "ISTJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ENTJ") {
        if (ot == "INFP" || ot == "INTP") {
          this.resultPercent = "100";
        } else if (
          ot == "ENFP" ||
          ot == "INFJ" ||
          ot == "ENFJ" ||
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "ENTP"
        ) {
          this.resultPercent = "75";
        } else {
          this.resultPercent = "50";
        }
      } else if (my == "INTP") {
        if (ot == "ENTJ" || ot == "ESTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INFP" ||
          ot == "ENFP" ||
          ot == "INFJ" ||
          ot == "ENFJ" ||
          ot == "INTJ" ||
          ot == "INTP" ||
          ot == "ENTP"
        ) {
          this.resultPercent = "75";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "50";
        } else {
          this.resultPercent = "25";
        }
      } else if (my == "ENTP") {
        if (ot == "INFJ" || ot == "INTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INFP" ||
          ot == "ENFP" ||
          ot == "ENFJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP"
        ) {
          this.resultPercent = "75";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "50";
        } else {
          this.resultPercent = "25";
        }
      } else if (my == "ISFP") {
        if (ot == "ENFJ" || ot == "ESFJ" || ot == "ESTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP" ||
          ot == "ISFJ" ||
          ot == "ISTJ"
        ) {
          this.resultPercent = "50";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ESFP") {
        if (ot == "ISFJ" || ot == "ISTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP" ||
          ot == "ESFJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "50";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ISTP") {
        if (ot == "ESFJ" || ot == "ESTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP" ||
          ot == "ISFJ" ||
          ot == "ISTJ"
        ) {
          this.resultPercent = "50";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ESTP") {
        if (ot == "ISFJ" || ot == "ISTJ") {
          this.resultPercent = "100";
        } else if (
          ot == "INTJ" ||
          ot == "ENTJ" ||
          ot == "INTP" ||
          ot == "ENTP" ||
          ot == "ESFJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "50";
        } else if (
          ot == "ISFP" ||
          ot == "ESFP" ||
          ot == "ISTP" ||
          ot == "ESTP"
        ) {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ISFJ") {
        if (ot == "ESFP" || ot == "ESTP") {
          this.resultPercent = "100";
        } else if (
          ot == "ISFJ" ||
          ot == "ESFJ" ||
          ot == "ISTJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "75";
        } else if (ot == "ISFP" || ot == "ISTP") {
          this.resultPercent = "50";
        } else if (ot == "INTJ" || ot == "INTP" || ot == "ENTP") {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ESFJ") {
        if (ot == "ISFP" || ot == "ISTP") {
          this.resultPercent = "100";
        } else if (
          ot == "ISFJ" ||
          ot == "ESFJ" ||
          ot == "ISTJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "75";
        } else if (ot == "ESFP" || ot == "ESTP") {
          this.resultPercent = "50";
        } else if (ot == "INTJ" || ot == "INTP" || ot == "ENTP") {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ISTJ") {
        if (ot == "ESFP" || ot == "ESTP") {
          this.resultPercent = "100";
        } else if (
          ot == "ISFJ" ||
          ot == "ESFJ" ||
          ot == "ISTJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "75";
        } else if (ot == "ISFP" || ot == "ISTP") {
          this.resultPercent = "50";
        } else if (ot == "INTJ" || ot == "INTP" || ot == "ENTP") {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      } else if (my == "ESTJ") {
        if (ot == "INTP" || ot == "ISFP" || ot == "ISTP") {
          this.resultPercent = "100";
        } else if (
          ot == "ISFJ" ||
          ot == "ESFJ" ||
          ot == "ISTJ" ||
          ot == "ESTJ"
        ) {
          this.resultPercent = "75";
        } else if (ot == "ENTJ" || ot == "ESFP" || ot == "ESTP") {
          this.resultPercent = "50";
        } else if (ot == "INTJ" || ot == "ENTP") {
          this.resultPercent = "25";
        } else {
          this.resultPercent = "0";
        }
      }
    },
    copyURL() {
      var dummy = document.createElement("input");
      var text = location.href + "/" + this.myMBTI + "/" + this.otherMBTI;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      alert("URL이 복사 되었습니다.");
    },
    shareFacebook: function () {
      var url =
        "https://www.facebook.com/sharer/sharer.php?u=" +
        baseURL +
        "/match/" +
        this.myMBTI +
        "/" +
        this.otherMBTI;
      var name = "팝업";
      var specs =
        "width=500,height=400,top=200,left=100,toolbar=no,menubar=no,scrollbars=no,resizable=yes";
      console.log("url:" + url);
      window.open(url, name, specs);
    },
    shareTwitter: function () {
      var url =
        "https://twitter.com/intent/tweet?" +
        "text=" +
        "친구와 당신의 궁합을 공유해보세요." +
        "&url=" +
        baseURL +
        "/match/" +
        this.myMBTI +
        "/" +
        this.otherMBTI;
      var name = "팝업";
      var specs =
        "width=500,height=400,top=200,left=100,toolbar=no,menubar=no,scrollbars=no,resizable=yes";
      console.log("url:" + url);
      window.open(url, name, specs);
    },
    shareKakao: function () {
      // alert(baseURL + "/match/" + this.myMBTI + "/" + this.otherMBTI);
      window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "MBTING",
          description: "상대방과의 MBTI와 궁합을 확인해보세요!",
          imageUrl: baseURL + "/assets/mbting-logo.jpg",
          link: {
            mobileWebUrl:
              baseURL + "/match/" + this.myMBTI + "/" + this.otherMBTI,
            webUrl:
              baseURL + "/match/" + this.myMBTI + "/" + this.otherMBTI,
          },
        },
        buttons: [
          {
            title: "웹에서 확인하기",
            link: {
              mobileWebUrl:
                baseURL + "/match/" + this.myMBTI + "/" + this.otherMBTI,
              webUrl:
                baseURL + "/match/" + this.myMBTI + "/" + this.otherMBTI,
            },
          },
        ],
        installTalk: true,
      });
    },
    make2array: function (row, col) {
      var arr = new Array(row);

      for (var i = 0; i < arr.length; i++) {
        arr[i] = new Array(col);
      }
      return arr;
    },
    initDataArray: function () {
      var arr = this.make2array(16, 16);

      for (var i = 0; i < arr.length; i++) {
        for (var j = 0; j < arr[i].length; j++) {
          arr[i][j] = this.items[j];
        }
      }
      return arr;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getBestWorstMbtiType(my, type) {
      if (type == "best") {
        if (my == "INFP") {
          this.myBestMBTI = "ENFJ, ENTJ";
        } else if (my == "ENFP") {
          this.myBestMBTI = "INFJ, INTJ";
        } else if (my == "INFJ") {
          this.myBestMBTI = "ENFP, ENTP";
        } else if (my == "ENFJ") {
          this.myBestMBTI = "INFP, ISFP";
        } else if (my == "INTJ") {
          this.myBestMBTI = "ENFP, ENTP";
        } else if (my == "ENTJ") {
          this.myBestMBTI = "INFP, INFP";
        } else if (my == "INTP") {
          this.myBestMBTI = "ENTJ, ESTJ";
        } else if (my == "ENTP") {
          this.myBestMBTI = "INFJ, INTJ";
        } else if (my == "ISFP") {
          this.myBestMBTI = "ENFJ, ESFJ, ESTJ";
        } else if (my == "ESFP") {
          this.myBestMBTI = "ISFJ, ISTJ";
        } else if (my == "ISTP") {
          this.myBestMBTI = "ESFJ, ESTJ";
        } else if (my == "ESTP") {
          this.myBestMBTI = "ISFJ";
        } else if (my == "ISFJ") {
          this.myBestMBTI = "ESFP, ESTP";
        } else if (my == "ESFJ") {
          this.myBestMBTI = "ISFP, ISTP";
        } else if (my == "ISTJ") {
          this.myBestMBTI = "ESFP";
        } else if (my == "ESTJ") {
          this.myBestMBTI = "INTP, ISFP, ISTP";
        } else {
          this.myBestMBTI = "Error";
        }
        return this.myBestMBTI;
      } else if (type == "worst") {
        if (my == "INFP" || my == "ENFP" || my == "INFJ") {
          this.myWorstMBTI = "ISFP, ESFP, ISTP, ESTP, ISFJ, ESFJ, ISTJ, ESTJ";
        } else if (my == "ENFJ") {
          this.myWorstMBTI = "ESFP, ISTP, ESTP, ISFJ, ESFJ, ISTJ, ESTJ";
        } else if (
          my == "INTJ" ||
          my == "ENTJ" ||
          my == "INTP" ||
          my == "ENTP"
        ) {
          this.myWorstMBTI = "없음";
        } else if (my == "ISFP") {
          this.myWorstMBTI = "INFP, ENFP, INFJ";
        } else if (
          my == "ESFP" ||
          my == "ISTP" ||
          my == "ESTP" ||
          my == "ISFJ" ||
          my == "ESFJ" ||
          my == "ISTJ" ||
          my == "ESTJ"
        ) {
          this.myWorstMBTI = "INFP, ENFP, INFJ, ENFJ";
        } else {
          this.myWorstMBTI = "Error";
        }
        return this.myWorstMBTI;
      }
    },
  },
};
</script>

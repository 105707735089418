import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
// import { loginUser, logoutUser, authVerifyToken } from "../services/api/auth";
import mbtingStore from "@/store/modules/mbtingStore.js"
import askmeStore from "@/store/modules/askmeStore.js"
import loginStore from "@/store/modules/loginStore.js";

Vue.use(Vuex);

const ls = new SecureLS({
  encodingType: 'aes', // changeable
})

const loginUserState = createPersistedState({
  modules: { loginStore },
  storage: {
    getItem: key => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: key => ls.remove(key)
  }
})

export default new Vuex.Store({
  modules: {
    mbtingStore: mbtingStore,
    askmeStore: askmeStore,
    loginStore: loginStore,
  },
  plugins: [loginUserState]
})
import Vue from "vue";
import VueI18n from "vue-i18n";

// 언어 JSON 파일 가져오기
import en from "../locales/en.json";
import ko from "../locales/ko.json";
import es from "../locales/es.json";
import fr from "../locales/fr.json";
import de from "../locales/de.json";
import ru from "../locales/ru.json";

Vue.use(VueI18n);

const savedLanguage = localStorage.getItem('user-lang') || 'ko';  // 기본값 'ko' (한국어)

const messages = { en, ko, es, fr, de, ru };

const i18n = new VueI18n({
  legacy: false, // Composition API 사용 시 false
  locale: savedLanguage,
  fallbackLocale: "ko", // 기본 언어 설정
  messages,
});

export default i18n;

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBci1_ScJrWqDiONP_CzuxDYQEZ44QFnag",
//   authDomain: "conpurple-cf538.firebaseapp.com",
//   projectId: "conpurple-cf538",
//   storageBucket: "conpurple-cf538.appspot.com",
//   messagingSenderId: "481088031112",
//   appId: "1:481088031112:web:4df1a56f732a23802c523d",
//   measurementId: "G-16MYDH24MW"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

import firebase from 'firebase/compat/app';
// import 'firebase/database'; // Realtime Database 사용 시
import 'firebase/compat/auth';
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyBci1_ScJrWqDiONP_CzuxDYQEZ44QFnag",
  authDomain: "conpurple-cf538.firebaseapp.com",
  databaseURL: 'conpurple-cf538.firebaseio.com',
  projectId: "conpurple-cf538",
  storageBucket: "conpurple-cf538.appspot.com",
  messagingSenderId: "481088031112",
  appId: "1:481088031112:web:4df1a56f732a23802c523d",
  measurementId: "G-16MYDH24MW"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

// Realtime Database
const database = firebase.database();

export { auth, googleProvider, database };


<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col>
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              위로
            </v-btn>
          </v-row>
        </v-card>
        <v-card v-if="isLogin">
          <v-row justify="center">
            <v-card-text
              class="my-4 text-h5 text-center blue-grey--text text--blue-grey darken-1"
              >친구에게 나의 MBTI 타입을 맞혀보라고 요청 해보세요!</v-card-text
            >
          </v-row>
          <v-row justify="center">
            <div class="d-flex">
              <!-- <v-text-field
                v-model="friendsname"
                :counter="10"
                :rules="namerules"
                label="친구 이름"
                clearable
                outlined
                required
                prepend-inner-icon="mdi-account-outline"
              ></v-text-field> -->
              <v-btn
                outlined
                rounded
                text
                class="ml-2 mt-2"
                @click="shareKaKaoToFriends()"
              >
                카카오톡으로 요청하기
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="getMyInfo"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                :sort-by="['id', 'date']"
                :sort-desc="[true, true]"
                item-key="id"
                class="elevation-0"
                @page-count="pageCount = $event"
                dense
                hide-default-footer
              >
              </v-data-table>
              <template v-slot:item="{ item }">
                <table
                  style="
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 0 5px;
                  "
                >
                  <td style="width: 6%">
                    <tr>
                      <td>
                        {{ item.userid }}
                      </td>
                    </tr>
                  </td>
                </table>
              </template>
            </v-col>
          </v-row>
          <v-dialog
            v-model="watchMakeMyPageDialog"
            hide-overlay
            persistent
            width="300"
          >
            <v-card color="black" dark>
              <v-card-text>
                Writting...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
        <v-card v-else>
          <v-row justify="center">
            <v-card-text
              class="my-4 text-center blue-grey--text text--blue-grey darken-1"
              >나의 MBTI 타입을 친구에게 맞혀보도록 요청 해보세요~<br />해당
              기능은 로그인 후 이용할 수 있습니다.</v-card-text
            >
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
let baseURL = "https://mbting.conpurple.com/";
let guessmeurl = "api/guessme/";
let answerurl = "https://mbting.conpurple.com/guessme/answer/";

export default {
  name: "MbtiGuessme",
  data: () => ({
    fab: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    friendsname: "",
    getMyInfo: [],
    watchMakeMyPageDialog: false,
    headers: [
      {
        text: "작성자",
        align: "start",
        sortable: false,
        value: "myname",
      },
      {
        text: "상대방이 생각하는 나의 MBTI 타입",
        value: "mbtitype",
        sortable: false,
      },
      { text: "코멘트", value: "comment", sortable: false },
      { text: "날짜", value: "date", sortable: false },
    ],
    namerules: [
      (v) => !!v || "이름은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 10) || "이름은 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[a-zA-Z0-9~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "이름에는 한글만 사용할 수 있습니다.",
    ],
  }),
  watch: {
    watchMakeMyPageDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.makeMyPage(), (this.watchMakeMyPageDialog = false)),
        1000
      );
    },
  },
  computed: {
    ...mapState("mbtingStore", ["isLoggedIn", "user"]),
    ...mapGetters("mbtingStore", ["isLogin", "getUserInfo"]),
  },
  created() {},
  mounted() {
    var token = localStorage.getItem("access_token");
    if (token != null) {
      var url = token.split(".")[1];
      var base64 = url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var userid = JSON.parse(jsonPayload).name;
      this.getGeussInfo(userid);
    }
    this.$vuetify.goTo(0);
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    shareKaKaoToFriends() {
      // if (this.friendsname == "") {
      //   alert("친구 이름을 입력하세요.");
      //   return;
      // }
      // console.log(window.Kakao)
      // baseURL;
      // alert(answerurl + this.getUserInfo.userid)
      window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "MBTING",
          description: "친구의 MBTI 타입을 맞혀보세요!",
          imageUrl: baseURL + "assets/mbting-logo.jpg",
          link: {
            mobileWebUrl: answerurl + this.getUserInfo.userid,
            webUrl: answerurl + this.getUserInfo.userid,
          },
        },
        buttons: [
          {
            title: "웹에서 확인하기",
            link: {
              mobileWebUrl: answerurl + this.getUserInfo.userid,
              webUrl: answerurl + this.getUserInfo.userid,
            },
          },
        ],
        // serverCallbackArgs: {
        //   userid: this.getUserInfo.userid,
        // },
        // success: function (response) {
        //   alert("success: " + response);
        // },
        // fail: function (error) {
        //   alert("error: " + error);
        // },
        installTalk: true,
      });
    },
    async getGeussInfo(userid) {
      console.log(guessmeurl + "?userid=" + userid)
      await axios({
        method: "GET",
        url: guessmeurl + "?userid=" + userid,
      })
        .then((response) => {
          this.getMyInfo = response.data.results;
        })
        .catch((error) => {
          console.log("axios get error: ", error);
        });
    },
  },
};
</script>

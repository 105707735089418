<template>
  <v-container>
    <div style="width: 100%; height: 100%">
      <v-btn
        v-if="user.is_logined == false"
        style="width: 100%; cursor: pointer; display: block; margin: 0 auto"
        color="green"
        @click="login()"
        :height="45"
      >
        <v-icon>mdi-google</v-icon><v-spacer></v-spacer>
        <p class="mt-3" style="font-size: 16px; font-weight: bold">
          {{ $t("app_login_google_button_title") }}
        </p>
        <v-spacer></v-spacer>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { auth, googleProvider } from "../firebase";

export default {
  components: {},
  data: () => ({
    user: {},
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.user = this.getUserInfo;
    // alert(JSON.stringify(this.user));
  },
  computed: {
    ...mapGetters("loginStore", ["getUserInfo"]), // store.js의 getters를 연결해주는 helper
  },
  methods: {
    async login() {

      googleProvider.setCustomParameters({ prompt: "select_account" });
      const { user } = await auth.signInWithPopup(googleProvider);
      const data = user.providerData[0];
      const token = user.toJSON().stsTokenManager;
      const userInfo = {
        name: data.displayName,
        email: data.email,
        photoUrl: data.photoURL,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      };

      this.googleLogin(userInfo);
      // TODO 구글 로그인은 팝업창이 떠서 coop로 인해 alert 수행하면 팝업창이 닫히지 않음.
      // alert("로그인 성공: " + ret);
      window.location.reload();
    },
    ...mapActions("loginStore", ["googleLogin"]),
    // async test() {
    //   await this.$store.dispatch('loginStore/googleLogin');
    //   console.log("get: " + JSON.stringify(this.getUserInfo));
    //   this.$router.go();
    // }
  },
  watch: {},
};
</script>
<style>
.image {
  width: 100px;
  max-width: 100%;
}
</style>

// import { auth, googleProvider } from '../../firebase';

const loginStore = {
    namespaced: true,
    state: {
        name: null,
        email: null,
        photo_url: null,
        access_token: null,
        refresh_token: null,
        login_type: null,
        is_logined: false,
    },
    getters: {
        getUserInfo(state) {
            // console.log("getUserInfo: " + JSON.stringify(state));
            return state;
        },
    },
    /* state를 변경하는 함수 */
    mutations: {
        setUserName(state, name) {
            state.name = name;
            // console.log("setUserName: " + JSON.stringify(state.name));
        },
        setUserEmail(state, email) {
            state.email = email;
            // console.log("setUserEmail: " + JSON.stringify(state.email));
        },
        setUserPhotoUrl(state, photo_url) {
            state.photo_url = photo_url;
        },
        setUserAccessToken(state, access_token) {
            state.access_token = access_token;
            // console.log("setUserAccessToken: " + JSON.stringify(state.access_token));
        },
        setUserRefreshToken(state, refresh_token) {
            state.refresh_token = refresh_token;
            // console.log("setUserRefreshToken: " + JSON.stringify(state.refresh_token));
        },
        setUserLoginType(state, type) {
            state.login_type = type;
            state.is_logined = true;
        },
        setUserinfo(state, name, email, photo_url, access_token, refresh_token, type) {
            state.name = name;
            state.email = email;
            state.photo_url = photo_url;
            state.access_token = access_token;
            state.refresh_token = refresh_token;
            state.login_type = type;
            state.is_logined = true;
            // console.log("setUserinfo: " + JSON.stringify(state))
        },
        delUserinfo(state) {
            state.name = null;
            state.email = null;
            state.photo_url = null;
            state.access_token = null;
            state.refresh_token = null;
            state.login_type = null;
            state.is_logined = false;
            // console.log("delUserinfo: " + JSON.stringify(state))
        },
    },
    /* 비동기 로직을 처리한 후 mutation을 실행함 */
    actions: {
        async googleLogin({ commit }, user_info) {
            try {
                const name = user_info.name;
                const email = user_info.email;
                const photoUrl = null;
                const accessToken = user_info.access_token;
                const refreshToken = user_info.refresh_token;
                const loginType = "google";

                commit("setUserName", name);
                commit("setUserEmail", email);
                commit("setUserPhotoUrl", photoUrl);
                commit("setUserAccessToken", accessToken);
                commit("setUserRefreshToken", refreshToken);
                commit("setUserLoginType", loginType);
            } catch (error) {
                console.log(error.message);
                commit("delUserinfo");
            }
        },
        async googleLogout({ commit }) {
            commit("delUserinfo");
            alert("로그아웃 되었습니다.");
        },
        kakaoLogin({ commit }, user_info) {
            const name = user_info.name;
            const email = user_info.email;
            const photoUrl = null;
            const accessToken = user_info.access_token;
            const refreshToken = user_info.refresh_token;
            const loginType = "kakao";

            commit("setUserName", name);
            commit("setUserEmail", email);
            commit("setUserPhotoUrl", photoUrl);
            commit("setUserAccessToken", accessToken);
            commit("setUserRefreshToken", refreshToken);
            commit("setUserLoginType", loginType);
            alert("로그인 되었습니다.");
        },
        kakaoLogout({ commit }) {
            commit("delUserinfo");
            alert("로그아웃 되었습니다.");
        },
        normalGoogleLogin({ commit }, user_info) {
            const name = user_info.name;
            const email = user_info.email;
            const photoUrl = null;
            const accessToken = user_info.access_token;
            const refreshToken = user_info.refresh_token;
            const loginType = "normal";

            commit("setUserName", name);
            commit("setUserEmail", email);
            commit("setUserPhotoUrl", photoUrl);
            commit("setUserAccessToken", accessToken);
            commit("setUserRefreshToken", refreshToken);
            commit("setUserLoginType", loginType);
            alert("로그인 되었습니다.");
        },
        normalGoogleLogout({ commit }) {
            commit("delUserinfo");
            alert("로그아웃 되었습니다.");
        },
    }
}

export default loginStore;

<template>
  <div>
    <v-container>
      <div class="d-flex mb-5 justify-center">
        <h1>{{ $t("mainprojectweb_container_name") }}</h1>
      </div>
      <v-card
        v-for="item in localizedWebProjects"
        class="mx-auto pa-10 ma-12"
        max-width="600"
        :key="item.idx"
        :id="item.idx"
        outlined
      >
        <v-img
          width="width"
          height="height"
          :src="item.icon"
          style="
            width: 120px;
            height: 60px;
            cursor: pointer;
            display: block;
            margin: 0 auto;
          "
          @click="gotoWeb(item.idx)"
        ></v-img>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-h5 mb-4">
              {{ item.title }}
            </div>
            <v-list-item-title class="text-h7 mb-1">
              {{ item.subtitle }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="justify-center">
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="gotoWeb(item.idx)"
          >
            Go site
          </v-btn>
          <!-- <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_board(0, index)"
        >
          Board
        </v-btn>
        <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_faq(0, index)"
        >
          FaQ
        </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    gotoWeb: function (index) {
      // alert("goto web: " + index);
      if (index == 4) {
        window.open("https://mbting.conpurple.com");
      } else if (index == 5) {
        window.open("https://lotto.conpurple.com");
      } else if (index == 6) {
        window.open("https://askme.conpurple.com");
      }
    },
    scrollToCard(cardId) {
      // 해당 카드의 ID로 요소 찾기
      const cardElement = document.getElementById(cardId);
      if (cardElement) {
        // 해당 요소로 스크롤 이동
        cardElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
  },
  mounted() {
    if (Object.keys(this.$route.params).length == 0) {
      window.scrollTo(0, 0);
    } else {
      // alert(JSON.stringify(this.$route.params.category));
      this.scrollToCard(this.$route.params.index);
    }
  },
  computed: {
    localizedWebProjects() {
      return [
        {
          title: "MBTing",
          subtitle: this.$t("mainprojectweb_web_project_0_subtitle"),
          description: this.$t("mainprojectweb_web_project_0_description"),
          button: this.$t("mainprojectweb_web_project_button"),
          idx: 4,
          icon: require("../assets/mbting_logo.jpeg"),
          color: "131313",
        },
        {
          title: "Let's Lotto",
          subtitle: this.$t("mainprojectweb_web_project_1_subtitle"),
          description: this.$t("mainprojectweb_web_project_1_description"),
          button: this.$t("mainprojectweb_web_project_button"),
          idx: 5,
          icon: require("../assets/lotto_logo.png"),
          color: "131313",
        },
        {
          title: "Askme",
          subtitle: this.$t("mainprojectweb_web_project_2_subtitle"),
          description: this.$t("mainprojectweb_web_project_2_description"),
          button: this.$t("mainprojectweb_web_project_button"),
          idx: 6,
          icon: require("../assets/askme_sample.png"),
          color: "131313",
        },
      ];
    },
  },
};
</script>

<template>
  <div>
    <v-container>
      <v-overlay v-model="loading">
        <div v-if="loading">
          <v-progress-circular indeterminate color="gray"></v-progress-circular>
        </div>
      </v-overlay>
      <!-- <v-card outlined color="transparent" class="mx-auto" max-width="600"> -->
      <v-container class="text-right">
        <v-btn class="ml-1 ms-1" @click="goback()">{{
          $t("mainboardpage_board_btn_list")
        }}</v-btn>
        <v-btn class="ms-1" @click="clickUpdateBtn()">{{
          $t("mainboardpage_board_btn_modify")
        }}</v-btn>
        <v-btn class="ms-1" @click="deleteBoard(board_id)">{{
          $t("mainboardpage_board_btn_delete")
        }}</v-btn>
      </v-container>
      <v-divider></v-divider>
      <v-list-item two-line dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-row class="text-left">
              <v-col cols="3">
                <v-card-text class="font-weight-bold"
                  >{{ $t("mainboardpage_board_type") }}
                  <v-list-item-subtitle
                    v-if="this.boardData.content_type == 'etc'"
                    class="mt-1"
                  >
                    <v-icon class="mt-2">mdi-web </v-icon>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else class="mt-1">
                    <v-img
                      class="mt-2"
                      rounded
                      :src="
                        convertContentTypeToIcon(this.boardData.content_type)
                      "
                      width="30px"
                      heigth="30px"
                    ></v-img>
                  </v-list-item-subtitle>
                </v-card-text>
              </v-col>
              <v-col cols="5">
                <v-card-text class="font-weight-bold"
                  >{{ $t("mainboardpage_board_date") }}
                  <v-list-item-subtitle class="mt-1">{{
                    convertTimestampToDate(this.boardData.create_timestamp)
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="mt-1">{{
                    convertTimestampToTime(this.boardData.create_timestamp)
                  }}</v-list-item-subtitle>
                </v-card-text>
              </v-col>
              <v-col cols="3">
                <v-card-text class="font-weight-bold"
                  >{{ $t("mainboardpage_board_viewer_count") }}
                  <v-list-item-subtitle class="mt-1">{{
                    this.boardData.hitcount
                  }}</v-list-item-subtitle>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="text-left">
              <v-col cols="12">
                <v-card-text class="font-weight-bold"
                  >{{ $t("mainboardpage_board_user") }}
                  <v-list-item-subtitle class="mt-1">{{
                    splitEmailMasking(this.boardData.email)
                  }}</v-list-item-subtitle>
                </v-card-text>
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-divider></v-divider>
          <v-list-item-title>
            <v-row class="text-left">
              <v-col cols="12">
                <v-card-text class="font-weight-bold"
                  >{{ $t("mainboardpage_board_subject") }}
                  <v-list-item-subtitle class="mt-1">{{
                    this.boardData.subject
                  }}</v-list-item-subtitle>
                </v-card-text>
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-divider></v-divider>
          <v-list-item-title>
            <v-row class="text-left">
              <v-col cols="12">
                <v-card-text class="font-weight-bold"
                  >{{ $t("mainboardpage_board_content") }}
                  <v-list-item-subtitle
                    class="mt-1"
                    style="white-space: normal"
                    >{{ this.boardData.content }}</v-list-item-subtitle
                  >
                </v-card-text>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- </v-card> -->
      <v-form>
        <v-text-field
          v-if="!writeReplyFormDialog"
          class="mt-4"
          :placeholder="$t('mainboardpage_board_input_reply')"
          @click="clickWriteReplyBtn()"
        ></v-text-field>
        <v-container v-else class="text-right">
          <v-textarea
            autofocus
            v-model="writeReplyFormContent"
            :counter="300"
            :rules="replyrules"
            clearable
            filled
            required
            :placeholder="$t('mainboardpage_board_input_reply')"
          ></v-textarea>
          <!-- <v-container > -->
          <v-btn
            text
            class="ml-1"
            border
            @click="writeReplyLoadingDialog = !writeReplyLoadingDialog"
            :disabled="writeReplyLoadingDialog"
            :loading="writeReplyLoadingDialog"
            >{{ $t("mainboardpage_board_btn_write") }}</v-btn
          >
          <v-btn
            text
            class="ml-1"
            variant="text"
            border
            @click="closeWriteReplyForm()"
            >{{ $t("mainboardpage_board_btn_close") }}</v-btn
          >
          <!-- </v-container> -->
        </v-container>
      </v-form>
      <!-- <v-card outlined color="transparent" class="mx-auto" max-width="600"> -->
      <v-row class="text-left">
        <p class="mt-3 ms-3">
          {{ $t("mainboardpage_board_text_reply") }}
          {{ this.boardReplyTotalCount }}
          {{ $t("mainboardpage_board_text_ea") }}
        </p>
      </v-row>
      <!-- </v-card> -->
      <v-divider class="mt-2"></v-divider>
      <v-data-table
        :items="boardReplyList"
        :page.sync="currentReplyPage"
        :items-per-page="itemsReplyPerPage"
        @page-count="totalReplyPages = $event"
        hide-default-header
        hide-default-footer
        show-expand
        :no-data-text="$t('mainboardpage_board_text_noreply')"
      >
        <template v-slot:item="{ item }">
          <v-card class="mb-3" elevation="0">
            <v-card-title>
              <v-icon>mdi-emoticon-excited-outline</v-icon>
              <span class="font-weight-bold ms-2 me-2 text-caption">{{
                splitEmailMasking(item.email)
              }}</span>
              <span class="ml-auto text-caption">
                ({{ convertTimestampToDate(item.create_timestamp) }}
                {{ convertTimestampToTime(item.create_timestamp) }})
              </span>
              <v-spacer></v-spacer>
              <v-menu v-if="user.email === item.email" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(option, i) in localizedReplyOption"
                    :key="i"
                  >
                    <v-btn text @click="clickReplyOption(i, item.id)">{{
                      option.title
                    }}</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>

            <v-card-text class="text-left">{{ item.content }}</v-card-text>

            <v-card-actions>
              <v-btn text @click="toggleComment(item.id)">
                {{ $t("mainboardpage_board_btn_reply") }}
                <b class="ml-1">{{ getRereplyListCount(item.id) }}</b>
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>

            <v-expand-transition>
              <div v-if="expandedPost === item.id" ref="expandedSection">
                <v-list>
                  <v-list-item
                    v-for="(reply, index) in getRereplyList(item.id)"
                    :key="index"
                  >
                    <v-container class="pa-0 ma-0">
                      <v-card class="mb-3" elevation="0">
                        <v-card-title>
                          <v-icon>mdi-arrow-right-bottom</v-icon>
                          <v-icon claas="ms-2"
                            >mdi-emoticon-excited-outline</v-icon
                          >
                          <span
                            class="font-weight-bold ms-2 me-2 text-caption"
                            >{{ splitEmailMasking(reply.email) }}</span
                          >

                          <span class="ml-auto text-caption">
                            ({{
                              convertTimestampToDate(reply.create_timestamp)
                            }}
                            {{
                              convertTimestampToTime(reply.create_timestamp)
                            }})
                          </span>
                          <v-spacer></v-spacer>
                          <v-menu v-if="user.email === reply.email">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-dots-vertical</v-icon
                              >
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(option, i) in localizedRereplyOption"
                                :key="i"
                              >
                                <v-btn
                                  text
                                  @click="clickRereplyOption(i, reply.id)"
                                  >{{ option.title }}</v-btn
                                >
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-card-title>

                        <v-card-text class="text-left ms-7">{{
                          reply.content
                        }}</v-card-text>
                      </v-card>
                    </v-container>
                  </v-list-item>
                </v-list>

                <v-text-field
                  v-if="!writeRereplyFormDialog"
                  class="mt-2"
                  :placeholder="$t('mainboardpage_board_input_rereply')"
                  @click="clickWriteRereplyBtn(item.id)"
                ></v-text-field>

                <v-expand-transition>
                  <v-container class="text-right">
                    <div v-if="writeRereplyFormDialog">
                      <v-textarea
                        v-model="writeRereplyFormContent"
                        :counter="300"
                        :rules="replyrules"
                        clearable
                        filled
                        required
                        :placeholder="$t('mainboardpage_board_input_rereply')"
                      ></v-textarea>
                      <v-btn
                        text
                        :loading="writeRereplyLoadingDialog"
                        @click="writeRereplyLoadingDialog = true"
                      >
                        {{ $t("mainboardpage_board_btn_write") }}
                      </v-btn>
                      <v-btn text @click="closeWriteRereplyForm()">{{
                        $t("mainboardpage_board_btn_close")
                      }}</v-btn>
                    </div>
                  </v-container>
                </v-expand-transition>

                <v-btn text @click="toggleComment(-1)"
                  ><b>{{ $t("mainboardpage_board_btn_reply_close") }}</b></v-btn
                >
                <v-divider></v-divider>
              </div>
            </v-expand-transition>
          </v-card>
        </template>
      </v-data-table>
      <v-pagination
        v-model="currentReplyPage"
        :length="totalReplyPages"
      ></v-pagination>
    </v-container>
    <v-dialog v-model="updateFormDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span>{{ $t("mainboardpage_board_modify_title") }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeUpdateForm()()">mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('mainboardpage_board_subject')"
            :counter="32"
            :rules="subjectRules"
            v-model="updateFormSubject"
            variant="outlined"
            required
          ></v-text-field>
          <v-textarea
            :label="$t('mainboardpage_board_content')"
            :counter="2000"
            :rules="contentRules"
            v-model="updateFormContent"
            variant="outlined"
            required
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="updateLoadingFormDialog = !updateLoadingFormDialog"
            :disabled="updateLoadingFormDialog"
            :loading="updateLoadingFormDialog"
            >{{ $t("mainboardpage_board_btn_modify") }}
          </v-btn>

          <v-btn @click="closeUpdateForm()"
            >{{ $t("mainboardpage_board_btn_close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateReplyFormDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span>{{ $t("mainboardpage_board_modify_reply_title") }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeUpdateReplyForm()">mdi-close </v-icon>
        </v-card-title>
        <v-container class="text-right">
          <v-textarea
            autofocus
            v-model="updateReplyFormContent"
            :counter="300"
            :rules="replyrules"
            variant="outlined"
            clearable
            filled
            required
          ></v-textarea>
          <!-- <v-container > -->
          <v-btn
            text
            @click="updateReplyLoadingDialog = !updateReplyLoadingDialog"
            :disabled="updateReplyLoadingDialog"
            :loading="updateReplyLoadingDialog"
            >{{ $t("mainboardpage_board_btn_modify") }}</v-btn
          >
          <v-btn text @click="closeUpdateReplyForm()">{{
            $t("mainboardpage_board_btn_close")
          }}</v-btn>
          <!-- </v-container> -->
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateRereplyFormDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span>{{ $t("mainboardpage_board_modify_rereply_title") }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeUpdateRereplyForm()">mdi-close </v-icon>
        </v-card-title>
        <v-container class="text-right">
          <v-textarea
            autofocus
            v-model="updateRereplyFormContent"
            :counter="300"
            :rules="replyrules"
            variant="outlined"
            clearable
            filled
            required
          ></v-textarea>
          <!-- <v-container > -->
          <v-btn
            text
            @click="updateRereplyLoadingDialog = !updateRereplyLoadingDialog"
            :disabled="updateRereplyLoadingDialog"
            :loading="updateRereplyLoadingDialog"
            >{{ $t("mainboardpage_board_btn_modify") }}</v-btn
          >
          <v-btn text @click="closeUpdateRereplyForm()">{{
            $t("mainboardpage_board_btn_close")
          }}</v-btn>
          <!-- </v-container> -->
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import MainBoardReplyWriteForm from "./MainBoardReplyWriteForm.vue";
// import MainBoardRereplyWriteForm from "./MainBoardRereplyWriteForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "MainBoardPage",
  components: {
    // MainBoardReplyWriteForm,
    // MainBoardRereplyWriteForm,
  },
  data: () => ({
    user: {},
    expandedPost: null,
    currentReplyPage: 1,
    itemsReplyPerPage: 5,
    totalReplyPages: 0,
    currentRereplyPage: 1,
    itemsRereplyPerPage: 5,
    totalRereplyPages: 0,
    singleExpand: true,
    board_id: 0,
    reply_id: 0,
    boardData: {},
    boardReplyList: [],
    boardRereplyList: [],
    boardReplyTotalCount: 0,
    autoContentTypeItems: [
      "wonderpod",
      "alldroid",
      "dotdroid",
      "qdroid",
      "mbting",
      "lotto",
      "askme",
    ],
    updateDialogPasswordForm: false,
    updateLoadingCheckPasswordDialog: false,
    updateFormDialog: false,
    updateLoadingFormDialog: false,

    updateFormSubject: "",
    updateFormContentType: "",
    updateFormContent: "",
    updateFormData: {
      subject: "",
      content_type: "",
      content: "",
      create_timestamp: 0,
    },
    updateReplyLoadingDialog: false,
    updateReplyFormDialog: false,
    updateReplyFormBoardId: "",
    updateReplyFormContent: "",
    updateReplyFormData: {
      content: "",
      create_date: "",
    },
    updateRereplyLoadingDialog: false,
    updateRereplyFormDialog: false,
    updateRereplyFormReplyId: "",
    updateRereplyFormContent: "",
    updateRereplyFormData: {
      content: "",
      create_date: "",
    },
    updateBoardHitcountData: {
      hitcount: 0,
    },
    updateBoardReplycountData: {
      reply_count: 0,
    },
    writeReplyLoadingDialog: false,
    writeReplyFormDialog: false,
    writeReplyFormContent: "",
    writeReplyFormData: {
      board_id: "",
      username: "",
      content: "",
      create_date: "",
    },
    writeRereplyLoadingDialog: false,
    writeRereplyFormDialog: false,
    writeRereplyFormContent: "",
    writeRereplyForm: {
      reply_id: "",
      email: "",
      content: "",
      create_date: "",
    },
    loading: false,
    expanded: [],
    subjectRules: [
      (v) => !!v || "제목은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 32) || "제목은 32자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    contentRules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 2000) || "내용은 2000자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    replyrules: [
      (v) => !(v && v.length >= 300) || "댓글은 300자 이상 입력할 수 없습니다.",
    ],
  }),
  methods: {
    convertContentTypeToIcon(content_type) {
      if (content_type == "wonderpod") {
        return require("../assets/wonderpod.png");
      } else if (content_type == "alldroid") {
        return require("../assets/alldroid_logo.png");
      } else if (content_type == "dotdroid") {
        return require("../assets/dotdroid_logo.png");
      } else if (content_type == "qdroid") {
        return require("../assets/qdroid_logo.png");
      } else if (content_type == "mbting") {
        return require("../assets/mbting_logo.jpeg");
      } else if (content_type == "lotto") {
        return require("../assets/lotto_logo.png");
      } else if (content_type == "askme") {
        return require("../assets/askme_sample.png");
      }
    },
    convertTimestampToDate(timestamp) {
      var tmp_date = new Date(timestamp);
      var tmp_year = tmp_date.getFullYear();
      var tmp_month = tmp_date.getMonth() + 1;
      if (tmp_month < 10) tmp_month = "0" + tmp_month;
      var tmp_day = tmp_date.getDate();
      if (tmp_day < 10) tmp_day = "0" + tmp_day;
      var tmp_new_date;

      tmp_new_date = tmp_year + "-" + tmp_month + "-" + tmp_day;
      // alert(tmp_new_date);
      return tmp_new_date;
    },
    convertTimestampToTime(timestamp) {
      var tmp_date = new Date(timestamp);
      var tmp_hour = tmp_date.getHours();
      if (tmp_hour < 10) tmp_hour = "0" + tmp_hour;
      var tmp_min = tmp_date.getMinutes();
      if (tmp_min < 10) tmp_min = "0" + tmp_min;
      var tmp_sec = tmp_date.getSeconds();
      if (tmp_sec < 10) tmp_sec = "0" + tmp_sec;
      var tmp_new_time;

      tmp_new_time = tmp_hour + ":" + tmp_min + ":" + tmp_sec;
      // alert(tmp_new_time);
      return tmp_new_time;
    },
    convertEmailMasking(email) {
      if (email == undefined || email == null || email == "") {
        return;
      }
      let originStr = email;
      let emailStr = originStr.match(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
      );
      let strLength;

      strLength = emailStr.toString().split("@")[0].length - 3;

      // ex1) abcdefg12345@naver.com => ab**********@naver.com
      // return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*');

      // ex2) abcdefg12345@naver.com => ab**********@nav******
      return originStr
        .toString()
        .replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*")
        .replace(/.{6}$/, "******");
    },
    convertName(email) {
      if (email == undefined || email == null || email == "") {
        return;
      }
      var initial = email.substr(0, 2);
      return initial;
    },
    splitEmailMasking(email) {
      if (email == undefined || email == null || email == "") {
        return;
      }
      let maskedName;
      maskedName = email.toString().split("@")[0];
      maskedName = maskedName.slice(0, 3) + "*".repeat(maskedName.length);
      return maskedName;
    },
    closeUpdateReplyForm() {
      this.updateReplyLoadingDialog = false;
      this.updateReplyFormDialog = false;
      this.updateReplyFormContent = "";
      this.updateReplyFormBoardId = "";
    },
    clickReplyOption(index, id) {
      if (index == 0) {
        if (this.user.is_logined == false) {
          alert(this.$t("mainboardpage_alert_btn_write"));
          return;
        }
        // alert("수정 : " + index);
        this.updateReplyFormDialog = true;
        for (var i = 0; i < this.boardReplyList.length; i++) {
          if (id == this.boardReplyList[i].id) {
            this.updateReplyFormContent = this.boardReplyList[i].content;
            this.updateReplyFormBoardId = id;
            break;
          }
        }
        // alert(JSON.stringify(this.updateReplyFormContent));
      } else if (index == 1) {
        this.deleteBoardReply(id);
      }
    },
    addRereply() {
      this.expanded.push({
        title: `아이템 ${this.expanded.length + 1}`,
        content: `이것은 ${this.expanded.length + 1}번째 아이템입니다.`,
      });
    },
    closeUpdateRereplyForm() {
      this.updateRereplyLoadingDialog = false;
      this.updateRereplyFormDialog = false;
      this.updateRereplyFormContent = "";
      this.updateRereplyFormReplyId = "";
    },
    clickRereplyOption(index, id) {
      if (index == 0) {
        if (this.user.is_logined == false) {
          alert(this.$t("mainboardpage_alert_btn_write"));
          return;
        }
        // alert("수정 : " + index);
        this.updateRereplyFormDialog = true;
        for (var i = 0; i < this.boardRereplyList.length; i++) {
          if (id == this.boardRereplyList[i].id) {
            this.updateRereplyFormContent = this.boardRereplyList[i].content;
            this.updateRereplyFormReplyId = id;
            break;
          }
        }
        // alert(JSON.stringify(this.updateRereplyFormContent));
      } else if (index == 1) {
        this.deleteBoardRereply(id);
      }
    },
    async getBoard() {
      await axios({
        method: "GET",
        url: "/api/mainboard/" + this.board_id,
      })
        .then((response) => {
          this.boardData = response.data;
        })
        .catch((response) => {
          console.log("axios getBoard GET error: ", response);
        });
    },
    async getBoardReplyList() {
      await axios({
        method: "GET",
        url: "/api/mainboardreply/" + "?board_id=" + this.boardData.id,
      })
        .then((response) => {
          this.boardReplyList = response.data.results;
          this.boardReplyTotalCount = response.data.results.length;
        })
        .catch((response) => {
          console.log("axios getBoardReplyList GET error: ", response);
        });
    },
    async getBoardRereplyList(reply_id) {
      await axios({
        method: "GET",
        url: "/api/mainboardrereply/" + "?reply_id=" + reply_id,
      })
        .then((response) => {
          for (var idx in response.data.results) {
            if (response.data.results[idx].reply_id == reply_id) {
              this.boardRereplyList.push(response.data.results[idx]);
              //   alert(
              //   "getBoardRereplyList: " + JSON.stringify(this.boardRereplyList)
              // );
            }
          }
        })
        .catch((response) => {
          console.log("axios getBoardRereplyList GET error: ", response);
        });
    },
    async deleteBoard(id) {
      if (this.user.email != this.boardData.email) {
        alert(this.$t("mainboardpage_alert_modify"));
        return;
      }

      if (confirm("정말로 삭제 하시겠습니까?")) {
        await axios({
          method: "DELETE",
          url: "/api/mainboard" + "/" + id,
        })
          .then(() => {
            alert(this.$t("mainboardpage_alert_success_delete"));
            this.goback();
          })
          .catch((error) => {
            console.log("axios deleteBoard DEL error: ", error.reponse);
          });
      }
    },
    async deleteBoardReply(id) {
      if (confirm(this.$t("mainboardpage_confirm_delete"))) {
        await axios({
          method: "DELETE",
          url: "/api/mainboardreply" + "/" + id,
        })
          .then(() => {
            this.updateBoardReplyCount(this.board_id);
            alert(this.$t("mainboardpage_alert_success_delete"));
            // window.location.reload();
          })
          .catch((error) => {
            console.log("axios deleteBoardReply DEL error: ", error.reponse);
          });
      }
    },
    async deleteBoardRereply(id) {
      if (confirm("정말로 삭제 하시겠습니까?")) {
        await axios({
          method: "DELETE",
          url: "/api/mainboardrereply" + "/" + id,
        })
          .then(() => {
            alert(this.$t("mainboardpage_alert_success_delete"));
            this.$router.go();
            // window.location.reload();
          })
          .catch((error) => {
            console.log("axios deleteBoardRereply DEL error: ", error.reponse);
          });
      }
    },
    closeUpdateForm() {
      this.updateFormDialog = false;
    },
    clickUpdateBtn() {
      if (this.user.email != this.boardData.email) {
        alert(this.$t("mainboardpage_alert_modify"));
        return;
      }
      this.updateFormDialog = true;
      this.updateFormContentType = this.boardData.content_type;
      this.updateFormSubject = this.boardData.subject;
      this.updateFormContent = this.boardData.content;
    },
    async updateBoard(id) {
      if (
        this.updateFormContentType == "" ||
        this.updateFormSubject == "" ||
        this.updateFormContent == ""
      ) {
        alert(this.$t("mainboardpage_alert_warning_modify"));
        this.updateLoadingFormDialog = false;
        return false;
      }

      this.updateFormData.subject = this.updateFormSubject;
      this.updateFormData.content_type = this.updateFormContentType;
      this.updateFormData.content = this.updateFormContent;
      this.updateFormData.create_timestamp = Date.now();

      await axios({
        method: "PATCH",
        url: "/api/mainboard" + "/" + id + "/",
        data: this.updateFormData,
      })
        .then(() => {
          alert(this.$t("mainboardpage_alert_success_modify"));
          this.updateFormDialog = false;
          this.updateLoadingFormDialog = false;
          window.location.reload();
        })
        .catch((error) => {
          console.log("axios board del error: ", error.reponse);
        });
    },
    async updateReply() {
      if (this.updateReplyFormContent == "") {
        alert(this.$t("mainboardpage_alert_warning_modify"));
        return false;
      }

      // this.updateReplyFormData.board_id = this.updateReplyFormBoardId;
      // this.updateReplyFormData.email = this.user.email;
      this.updateReplyFormData.content = this.updateReplyFormContent;
      this.updateReplyFormData.create_timestamp = Date.now();

      await axios({
        method: "PATCH",
        url: "/api/mainboardreply/" + this.updateReplyFormBoardId + "/",
        data: this.updateReplyFormData,
      })
        .then(() => {
          alert(this.$t("mainboardpage_alert_success_modify"));
          this.closeUpdateReplyForm();
          location.reload(true);

          return true;
        })
        .catch((error) => {
          console.log("axios updateReply PATCH error: ", error.reponse);
          this.closeUpdateReplyForm();
          alert(this.$t("mainboardpage_alert_fail_modify"));
          return false;
        });
    },
    async updateRereply() {
      if (this.updateRereplyFormContent == "") {
        alert(this.$t("mainboardpage_alert_warning_modify"));
        return false;
      }

      // this.updateReplyFormData.board_id = this.updateReplyFormBoardId;
      // this.updateReplyFormData.email = this.user.email;
      this.updateRereplyFormData.content = this.updateRereplyFormContent;
      this.updateRereplyFormData.create_timestamp = Date.now();

      await axios({
        method: "PATCH",
        url: "/api/mainboardrereply/" + this.updateRereplyFormReplyId + "/",
        data: this.updateRereplyFormData,
      })
        .then(() => {
          alert(this.$t("mainboardpage_alert_success_modify"));
          this.closeUpdateRereplyForm();
          location.reload(true);

          return true;
        })
        .catch((error) => {
          console.log("axios updateRereply PATCH error: ", error.reponse);
          this.closeUpdateRereplyForm();
          alert(this.$t("mainboardpage_alert_fail_modify"));
          return false;
        });
    },
    goback: function () {
      this.$router.go(-1);
    },
    closeWriteReplyForm() {
      this.writeReplyLoadingDialog = false;
      this.writeReplyFormDialog = false;
    },
    clickWriteReplyBtn() {
      if (this.user.is_logined == false) {
        alert(this.$t("mainboardpage_alert_btn_write"));
        return;
      }
      this.writeReplyFormDialog = true;
    },
    async updateBoardReplyCount(id) {
      await this.getBoardReplyList();

      this.updateBoardReplycountData.reply_count = this.boardReplyTotalCount;
      await axios({
        method: "PATCH",
        url: "/api/mainboard/" + id + "/",
        data: this.updateBoardReplycountData,
      })
        .then((response) => {
          this.$router.go();
          console.log("axios updateBoardReplyCount PATCH success: " + response);
        })
        .catch((error) => {
          console.log(
            "axios updateBoardReplyCount PATCH error: ",
            error.reponse
          );
        });
    },
    async writeReply() {
      if (this.writeReplyFormContent == "") {
        alert(this.$t("mainboardpage_alert_warning_modify"));
        return false;
      }

      this.writeReplyFormData.board_id = this.board_id;
      this.writeReplyFormData.email = this.user.email;
      this.writeReplyFormData.content = this.writeReplyFormContent;
      this.writeReplyFormData.create_timestamp = Date.now();

      await axios({
        method: "POST",
        url: "/api/mainboardreply/",
        data: this.writeReplyFormData,
      })
        .then(() => {
          this.closeWriteReplyForm();
          this.updateBoardReplyCount(this.board_id);
          alert(this.$t("mainboardpage_alert_success_write"));
          // location.reload(true);
          return true;
        })
        .catch((error) => {
          this.closeWriteReplyForm();
          console.log("axios writeReply POST error: ", error.reponse);
          alert(this.$t("mainboardpage_alert_fail_reply_write"));
          return false;
        });
    },
    closeWriteRereplyForm() {
      this.writeRereplyLoadingDialog = false;
      this.writeRereplyFormDialog = false;
    },
    clickWriteRereplyBtn(id) {
      if (this.user.is_logined == false) {
        alert(this.$t("mainboardpage_alert_btn_write"));
        return;
      }
      this.reply_id = id;
      this.writeRereplyFormDialog = true;
    },
    async writeRereply() {
      if (this.writeRereplyFormContent == "") {
        alert(this.$t("mainboardpage_alert_warning_modify"));
        return false;
      }

      this.writeRereplyForm.reply_id = this.reply_id;
      this.writeRereplyForm.email = this.user.email;
      this.writeRereplyForm.content = this.writeRereplyFormContent;
      this.writeRereplyForm.create_timestamp = Date.now();
      // alert(JSON.stringify(this.writeRereplyForm));
      await axios({
        method: "POST",
        url: "/api/mainboardrereply/",
        data: this.writeRereplyForm,
      })
        .then(() => {
          this.closeWriteRereplyForm();
          // this.updateBoardRereplyCount(this.reply_id);
          alert(this.$t("mainboardpage_alert_success_rereply_write"));
          this.$router.go();
          return true;
        })
        .catch((error) => {
          this.closeWriteRereplyForm();
          console.log("axios writeRereply POST error: ", error.reponse);
          alert(this.$t("mainboardpage_alert_fail_rereply_write"));
          return false;
        });
    },
    async setBoardHitcount() {
      this.updateBoardHitcountData = this.boardData;
      this.updateBoardHitcountData.hitcount += 1;
      // console.log(JSON.stringify(this.updateBoardHitcountData));

      await axios({
        method: "PATCH",
        url: "/api/mainboard/" + this.board_id + "/",
        data: this.updateBoardHitcountData,
      })
        .then(() => {
          // alert("HIT 카운트 증가 성공!");
          return true;
        })
        .catch((error) => {
          console.log("axios setBoardHitcount POST error: ", error.reponse);
          return false;
        });
    },
    getRereplyList(id) {
      var obj = [];
      this.boardRereplyList.forEach(function (entry) {
        if (entry.reply_id == id) {
          obj.push(entry);
        }
      });
      return obj;
    },
    getRereplyListCount(id) {
      var obj = [];
      this.boardRereplyList.forEach(function (entry) {
        if (entry.reply_id == id) {
          obj.push(entry);
        }
      });
      return obj.length;
    },
    toggleComment(id) {
      this.writeRereplyFormDialog = false;
      if (this.expandedPost === id) {
        this.expandedPost = null;
      } else {
        this.expandedPost = id;
        this.$nextTick(() => {
          // Scroll to the expanded section after DOM update
          const expandedSection = this.$refs.expandedSection;
          if (expandedSection) {
            expandedSection.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        });
      }
    },
  },
  async created() {
    this.loading = true;
    this.user = this.getUserInfo;
    this.board_id = this.$route.params.id;
    await this.getBoard();
    await this.getBoardReplyList();
    for (let i = 0; i < this.boardReplyTotalCount; i++) {
      await this.getBoardRereplyList(this.boardReplyList[i].id);
    }
    // hitcount 증가
    await this.setBoardHitcount();
    this.loading = false;
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    updateLoadingFormDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateBoard(this.board_id), 1000);
    },
    writeReplyLoadingDialog(val) {
      if (!val) return;

      setTimeout(
        () => this.writeReply(),

        1000
      );
    },
    writeRereplyLoadingDialog(val) {
      if (!val) return;

      setTimeout(
        () => this.writeRereply(),

        1000
      );
    },
    updateReplyLoadingDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateReply(), 1000);
    },
    updateRereplyLoadingDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateRereply(), 1000);
    },
    loading(val) {
      val &&
        setTimeout(() => {
          this.loading = false;
        }, 2000);
    },
  },
  computed: {
    ...mapGetters("loginStore", ["getUserInfo"]), // store.js의 getters를 연결해주는 helper
    // totalReplyPages() {
    //   return Math.ceil(this.boardReplyList.length / this.itemsReplyPerPage);
    // },
    // paginatedReplyItems() {
    //   const start = (this.currentReplyPage - 1) * this.itemsReplyPerPage;
    //   const end = start + this.itemsReplyPerPage;
    //   return this.boardReplyList.slice(start, end);
    // },
    localizedReplyOption() {
      return [
        { title: this.$t("mainboardpage_board_btn_modify"), index: 0 },
        { title: this.$t("mainboardpage_board_btn_delete"), index: 1 },
      ];
    },
    localizedRereplyOption() {
      return [
        { title: this.$t("mainboardpage_board_btn_modify"), index: 0 },
        { title: this.$t("mainboardpage_board_btn_delete"), index: 1 },
      ];
    },
  },
};
</script>
<style scoped>
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import LottoRandom from "../components/LottoRandom"
import LottoHistory from "../components/LottoHistory"
import LottoSimulator from "../components/LottoSimulator"
import LottoMain from "../components/LottoMain"

import MainHome from "../components/MainHome";
import MainCompanyMember from "@/components/MainCompanyMember.vue";
import MainCompanyHistory from "@/components/MainCompanyHistory.vue";
import MainProjectAndroid from "@/components/MainProjectAndroid.vue";
import MainProjectWeb from "@/components/MainProjectWeb.vue";
import MainBoard from "@/components/MainBoard.vue";
import MainBoardPage from "@/components/MainBoardPage.vue";
// import MainBoardReplyWriteForm from "@/components/MainBoardReplyWriteForm.vue";

Vue.use(VueRouter)
// export default new Router

const routes = [ 
        {
        path: "/",
        name: "/",
        component: MainHome,  
    },
    {
        path: "/company_member",
        name: "MainCompanyMember",
        component: MainCompanyMember,
    },
    {
        path: "/company_history",
        name: "MainCompanyHistory",
        component: MainCompanyHistory,
    },
    {
        path: "/project_android",
        name: "MainProjectAndroid",
        component: MainProjectAndroid,
    },
    {
        path: "/project_web",
        name: "MainProjectWeb",
        component: MainProjectWeb,
    },
    {
        path: "/mainboard",
        name: "MainBoard",
        component: MainBoard,
    },
    {
        path: "/mainboard/:id",
        name: "MainBoardPage",
        component: MainBoardPage,
        // children: [
        //     {
        //         path: "/mainboardreply/:board_id",
        //         name: "MainBoardReplyWriteForm",
        //         component: MainBoardReplyWriteForm
        //     }
        // ],
    },
    {
        path : "/lottomain",
        name : "/LottoMain",
        component: LottoMain
    },
    {
        path : "/lottorandom",
        name : "/LottoRandom",
        component: LottoRandom
    },
    {
        path : "/lottohistory",
        name : "/LottoHistory",
        component: LottoHistory
    }, 
    {
        path : "/lottosimulator",
        name : "/LottoSimulator",
        component : LottoSimulator
    }
]
const router = new VueRouter({
    mode: 'history',
    routes,
})
export default router
<template>
  <v-app>
    <v-app-bar color="white" elevation="0" app>
      <router-link :to="{ path: '/' }">
        <v-img
          style="display: block; margin: 5px"
          :src="require('@/assets/l2p_logo.png')"
          width="120px"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <p style="display: block; margin: 10px; font-size: 0.7em">
        {{ $t("app_today_visitor_title") }}: {{ visitDayCountData.count }}
        <br />
        {{ $t("app_today_total_visitor_title") }}:
        {{ visitTotalCountData.total_count }}
      </p>
      <v-icon
        v-if="user.is_logined == false"
        color="darken-3"
        style="cursor: pointer"
        class="mr-2"
        @click="loginFormDialog = true"
        >mdi-account</v-icon
      >

      <v-icon
        v-if="user.is_logined == true"
        id="menu-activator"
        color="darken-3"
        style="cursor: pointer"
        class="mr-2"
        >mdi-account-edit</v-icon
      >

      <v-menu activator="#menu-activator">
        <v-card class="mx-auto pa-2" max-width="250" min-width="100">
          <!-- <v-list-item-content class="justify-center"> -->
          <v-card-title class="justify-center">
            <v-icon size="40">mdi-account-box</v-icon>
          </v-card-title>
          <p>{{ user.email }}</p>
          <v-divider></v-divider>
          <v-card-actions v-if="user.login_type == 'normal'">
            <!-- <v-spacer></v-spacer> -->
            <v-btn class="mt-3" variant="text" plain @click="toggleChangePW">{{
              $t("app_change_password_title")
            }}</v-btn>
            <v-btn
              class="mt-3"
              color="primary"
              variant="text"
              plain
              @click="unregister()"
              >{{ $t("app_unregister_title") }}</v-btn
            >
            <!-- <v-spacer></v-spacer> -->
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions class="justify-center"
            ><v-btn text @click="logout()">
              <v-icon>mdi-logout</v-icon>{{ $t("app_logout_title") }}</v-btn
            ></v-card-actions
          >
          <!-- <div class="mx-auto text-center">
              <v-col style="height: 50px">
                <h4>{{ user.email }}</h4>
              </v-col>
              <v-divider v-if="user.login_type == 'normal'"></v-divider>
              <v-card-actions v-if="user.login_type == 'normal'">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="user.login_type == 'normal'"
                  class="mt-3"
                  variant="text"
                  plain
                  @click="toggleChangePW"
                  >비밀번호 변경</v-btn
                >
                <v-btn
                  v-if="user.login_type == 'normal'"
                  class="mt-3"
                  color="primary"
                  variant="text"
                  plain
                  @click="unregister()"
                  >탈퇴</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </div> -->
          <!-- </v-list-item-content> -->
          <!-- <v-divider></v-divider> -->
          <!-- <v-row>
            <v-col class="text-right">
              <v-btn text @click="logout()">
                <v-icon>mdi-logout</v-icon>로그아웃</v-btn>
            </v-col>
          </v-row> -->
          <v-spacer></v-spacer>
        </v-card>
      </v-menu>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" right app temporary>
      <v-list>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content class="text-left">
              <v-list-item-title class="ms-2">{{
                $t("app_navi_menu_introduce_l2p_title")
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(item, i) in localizedCompanyClippedMenuItems"
            :key="i"
            :prepend-icon="item.icon"
            :to="item.path"
            ><v-list-item-content>{{
              item.title
            }}</v-list-item-content></v-list-item
          >
        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content class="text-left">
              <v-list-item-title class="ms-2">{{
                $t("app_navi_menu_introduce_project_title")
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in localizedProjectClippedMenuItems"
            :key="i"
            :prepend-icon="item.icon"
            :to="item.path"
            ><v-list-item-content>{{
              item.title
            }}</v-list-item-content></v-list-item
          >
        </v-list-group>
        <v-list-item
          v-for="(item, i) in localizedMenuItems"
          :key="i"
          :to="item.path"
          ><v-list-item-content class="text-left"
            ><v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="loginFormDialog" width="400px">
      <!-- <v-row justify="center" align="center">
          <v-col cols="12"> -->
      <v-card v-if="user.is_logined == false">
        <v-card-title style="width: 100%; height: 60px">
          <div class="text-h6 text-medium-emphasis ps-2">
            {{ $t("app_login_form_title") }}
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="closeLoginForm">mdi-close </v-icon>
        </v-card-title>
        <v-card-text style="width: 100%; height: 140px">
          <v-form ref="loginForm" v-model="loginFormvalid">
            <v-text-field
              style="width: 100%; height: 60px"
              v-model="loginEmail"
              :label="$t('app_lable_email_title')"
              :rules="[rules.required, rules.email]"
              required
              prepend-inner-icon="mdi-email"
            ></v-text-field>
            <v-text-field
              style="width: 100%; height: 60px"
              v-model="loginPassword"
              :label="$t('app_lable_password_title')"
              type="password"
              :rules="[rules.required, rules.minPassword]"
              required
              prepend-inner-icon="mdi-lock"
              @keyup.enter="login()"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-2">
          <v-row justify="end" no-gutters>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn text @click="toggleResetPW">
                {{ $t("app_reset_password_button_title") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                text
                @click="login()"
                :disabled="!loginFormvalid"
                :loading="loginLoading"
              >
                {{ $t("app_login_button_title") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn text @click="toggleSignup">
                {{ $t("app_register_button_title") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-divider></v-divider>
        <v-card-text class="mt-2" style="width: 100%; height: 160px">
          <v-row no-gutters
            ><v-col>
              <MainKakaoLogin />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <MainGoogleLogin />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- </v-col>
          </v-row> -->
    </v-dialog>
    <v-dialog v-model="changePasswordFormDialog" max-width="400px">
      <v-card v-if="changePasswordFormDialog" class="mt-5">
        <v-card-title class="text-center">{{
          $t("app_reset_password_title")
        }}</v-card-title>

        <v-card-text>
          <v-form ref="signupForm" v-model="changePasswordFormValid">
            <v-text-field
              v-model="changePassword"
              :label="$t('app_lable_password_title')"
              type="password"
              :rules="[rules.required, rules.minPassword]"
              required
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <v-text-field
              v-model="changePassword2"
              :label="$t('app_lable_re_password_title')"
              type="password"
              :rules="[rules.required2, rules.minPassword2]"
              required
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <v-card-text style="color: red">{{
              this.changePasswordInfo
            }}</v-card-text>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            @click="changePW(changePassword)"
            :disabled="!changePasswordType"
          >
            {{ $t("app_modify_password_button_title") }}
          </v-btn>
          <v-btn text @click="toggleChangePW">{{
            $t("app_modify_password_cancle_button_title")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="signupFormDialog" max-width="400px">
      <v-card v-if="signupFormDialog" class="mt-5">
        <v-card-title class="text-center">{{
          $t("app_register_title")
        }}</v-card-title>

        <v-card-text>
          <v-form ref="signupForm" v-model="signupFormValid">
            <v-text-field
              v-model="signupEmail"
              :label="$t('app_lable_email_title')"
              :rules="[rules.required, rules.email]"
              required
              prepend-inner-icon="mdi-email"
            ></v-text-field>
            <v-text-field
              v-model="signupPassword"
              :label="$t('app_lable_password_title')"
              type="password"
              :rules="[rules.required, rules.minPassword]"
              required
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <v-text-field
              v-model="signupPassword2"
              :label="$t('app_lable_re_password_title')"
              type="password"
              :rules="[rules.required2, rules.minPassword2]"
              required
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <v-card-text style="color: red">{{
              this.signupPasswordInfo
            }}</v-card-text>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="signup" :disabled="!signupFormValid">
            {{ $t("app_register_button_title") }}
          </v-btn>
          <v-btn text @click="toggleSignup">{{
            $t("app_register_cancle_button_title")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetPasswordFormDialog" max-width="400px">
      <v-card v-if="resetPasswordFormDialog" class="mt-5">
        <v-card-title class="text-center">{{
          $t("app_reset_password_title")
        }}</v-card-title>

        <v-card-text>
          <v-form ref="signupForm" v-model="resetPasswordFormValid">
            <v-text-field
              v-model="resetPasswordEmail"
              :label="$t('app_lable_email_title')"
              :rules="[rules.required, rules.email]"
              required
              prepend-inner-icon="mdi-email"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            @click="sendEmail(resetPasswordEmail)"
            :disabled="!resetPasswordFormValid"
          >
            {{ $t("app_send_email_button_title") }}
          </v-btn>
          <v-btn text @click="toggleResetPW">{{
            $t("app_send_email_cancle_button_title")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main style="width: 600px">
      <router-view></router-view>
    </v-main>
    <v-btn
      fab
      color="transparent"
      @click="languageMenuVisible = !languageMenuVisible"
      class="floating-btn-above-footer"
    >
      <v-icon size="40">mdi-web</v-icon>
    </v-btn>
    <v-dialog
      v-model="languageMenuVisible"
      max-width="400px"
      persistent
      scrollable
    >
      <v-overlay v-model="languageLoading">
        <div v-if="languageLoading">
          <v-progress-circular indeterminate color="gray"></v-progress-circular>
        </div>
      </v-overlay>
      <v-card>
        <v-card-title>
          {{ $t("app_select_language") }}
          <v-spacer></v-spacer>
          <v-icon @click="languageMenuVisible = false">mdi-close </v-icon>
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="$i18n.locale"
            :items="languages"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
          <div v-if="!languageLoading" class="left-align">
            <p>
              {{ $t("app_selected_language") }}: {{ getSelectedLanguageText }}
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer color="black" app>
      <v-row align="center" justify="center" class="white--text">
        <v-col class="text-center">
          <h5>
            Copyright(c) {{ new Date().getFullYear() }}. l2pcompany All rights
            reserved.
          </h5>
          <h6>
            {{ $t("app_footer_business_title") }} : 448-81-03310
            {{ $t("app_footer_company_title") }}
          </h6>
          <h6>E-mail: anonyl2p@gmail.com</h6>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "axios";
import MainKakaoLogin from "./components/MainKakaoLogin.vue";
import MainGoogleLogin from "./components/MainGoogleLogin.vue";
import { auth } from "./firebase";

export default {
  name: "App",

  components: {
    MainKakaoLogin: MainKakaoLogin,
    MainGoogleLogin: MainGoogleLogin,
  },
  data: () => ({
    languages: [
      { text: "🇬🇧 English", value: "en" },
      { text: "🇰🇷 한국어", value: "ko" },
      { text: "🇪🇸 Español", value: "es" },
      { text: "🇫🇷 Français", value: "fr" },
      { text: "🇩🇪 Deutsch", value: "de" },
      { text: "🇷🇺 Русский", value: "ru" },
    ],
    languageMenuVisible: false,
    languageLoading: false,
    user: {},
    tab: 0,
    drawer: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    menuItems: [
      ["게시판", "/mainboard"],
      // ["FAQ", "/mainfaq"],
    ],
    companyClippedMenuItems: [
      ["구성원", "mdi-account-multiple", "/company_member"],
      ["연혁", "mdi-history", "/company_history"],
    ],
    projectClippedMenuItems: [
      ["안드로이드 프로젝트", "", "/project_android"],
      ["웹 프로젝트", "", "/project_web"],
      // ["Investment", "", "/project_investment"],
    ],
    // visitCount: 0,
    todayDate: "",
    visitDayCountData: {
      id: "",
      date: "",
      count: 0,
      // total_count: 0,
    },
    visitTotalCountData: {
      id: "",
      total_count: 0,
    },
    removeBoardList: [],
    removeBoardReplyList: [],
    loginFormDialog: false,
    loginFormvalid: false,
    loginEmail: "",
    loginPassword: "",

    signupFormDialog: false,
    signupFormValid: false,
    signupEmail: "",
    signupPassword: "",
    signupPassword2: "",
    signupPasswordType: 0,
    signupPasswordInfo: "",

    resetPasswordFormDialog: false,
    resetPasswordFormValid: false,
    resetPasswordEmail: "",

    changePasswordFormDialog: false,
    changePasswordFormValid: false,
    changePasswordEmail: "",
    changePassword: "",
    changePassword2: "",
    changePasswordType: 0,
    changePasswordInfo: "",
    loginLoading: false,
    // rules: {
    //    required: (value) => !!value || "123",
    //   required2: (value) => !!value || "123",
    //   email: (value) =>
    //     /.+@.+\..+/.test(value) || "123",
    //   minPassword: (value) =>
    //     value.length >= 6 || "123",
    //   minPassword2: (value) =>
    //     value.length >= 6 || "123",

    // },
  }),
  created() {
    // alert(this.$route.query.code);
    // if (this.$route.query.code) {
    //   this.setKakaoToken(this.$route.query.code)
    // }
    // const urlParams = new URLSearchParams(window.location.search);
    // if (urlParams.has("code")) {
    //   const code = urlParams.get("code");
    //   this.setKakaoToken(code);
    // }
    this.user = this.getUserInfo;
    // console.log("info: " + JSON.stringify(this.getUserInfo));
  },
  mounted() {
    var date = new Date();
    var str_year = date.getFullYear();
    var str_month = date.getMonth() + 1;
    var str_day = date.getDate();
    var full_date;

    if (str_month < 10) str_month = "0" + str_month;
    if (str_day < 10) str_day = "0" + str_day;

    full_date = str_year + "" + str_month + "" + str_day;
    this.todayDate = full_date;
    this.getVisitDayCount();
  },
  unmounted() {},
  methods: {
    closeLoginForm() {
      this.loginFormDialog = false;
      this.clearLoginForm();
    },
    // https://developers.kakao.com/docs/latest/ko/kakaologin/rest-api#request-token
    // async setKakaoToken(code) {
    //   const { data } = await getKakaoToken(code);
    //   if (data == undefined) {
    //     return;
    //   }
    //   // alert(JSON.stringify(data));
    //   // alert(data.access_token);
    //   // alert(data.refresh_token);
    //   console.error(
    //     "access: " + data.access_token + ", refresh: " + data.refresh_token
    //   );
    //   if (data.error) {
    //     console.log(data.error);
    //     return;
    //   }
    //   window.Kakao.Auth.setAccessToken(data.access_token);
    //   await this.setUserInfo(data.access_token, data.refresh_token);
    //   this.$router.push({ path: "/" });
    // },
    // // https://developers.kakao.com/docs/latest/ko/kakaologin/js#req-user-info
    // async setUserInfo(access_token, refresh_token) {
    //   const res = await getKakaoUserInfo();
    //   const userInfo = {
    //     name: res.kakao_account.profile.nickname,
    //     email: res.kakao_account.email,
    //     access_token: access_token,
    //     refresh_token: refresh_token,
    //   };
    //   // alert(JSON.stringify(userInfo));
    //   // console.log(userInfo);
    //   // this.user = userInfo;
    //   this.kakaoLogin(userInfo);
    //   this.$router.go();
    // },
    clearSignupForm() {
      this.signupEmail = "";
      this.signupPassword = "";
      this.signupPassword2 = "";
      this.signupPasswordType = 0;
      this.signupPasswordInfo = "";
    },
    clearLoginForm() {
      this.loginEmail = "";
      this.loginPassword = "";
    },
    clearResetPasswordForm() {
      this.resetPasswordEmail = "";
    },
    login() {
      this.loginLoading = true;
      // const auth = getAuth();
      auth
        .signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
        .then((userCredential) => {
          // 로그인 성공
          const user = userCredential.user;
          const token = user.toJSON().stsTokenManager;
          // alert(`로그인 성공: ${user.email}`);
          // alert(JSON.stringify(user));

          const userInfo = {
            name: "",
            email: user.email,
            access_token: token.accessToken,
            refresh_token: token.refreshToken,
          };
          // this.setUserInfo(token.accessToken, token.refreshToken);
          // alert(JSON.stringify(userInfo));
          this.normalGoogleLogin(userInfo);
          this.clearLoginForm();
          this.$router.go();
        })
        .catch((error) => {
          // 오류 처리
          if (error) {
            if (error.code == "auth/invalid-credential") {
              alert(this.$t("app_alert_check_password"));
            } else if (error.code == "auth/invalid-email") {
              alert(this.$t("app_alert_check_email"));
            } else if (error.code == "auth/user-disabled") {
              alert(this.$t("app_alert_check_account"));
            } else {
              alert(this.$t("app_alert_check_no_response"));
            }
          }
          // alert(`로그인 실패: ${error.message}`);
        });
    },
    signup() {
      if (this.signupPasswordType == 0) {
        alert(this.$t("app_alert_check_password"));
        return;
      }

      // const auth = getAuth();
      auth
        .createUserWithEmailAndPassword(this.signupEmail, this.signupPassword)
        .then((userCredential) => {
          // 회원가입 성공
          const user = userCredential.user;
          alert(this.$t("app_alert_success_register") + ": " + user.email);
          this.signupFormDialog = false; // 회원가입 후 로그인 화면으로 전환
          this.$router.go();
        })
        .catch((error) => {
          // 오류 처리
          if (error) {
            // alert(JSON.stringify(error));
            if (error.code == "auth/email-already-in-use") {
              alert(this.$t("app_alert_already_account"));
            } else if (error.code == "auth/invalid-email") {
              alert(this.$t("app_alert_check_email"));
            } else {
              alert(
                this.$t("app_alert_success_register") + ": " + error.message
              );
            }
          }
        });
    },
    async unregister() {
      if (confirm(this.$t("app_confirm_unrgister"))) {
        // const auth = getAuth();
        const user = auth.currentUser;
        // alert("user: " + JSON.stringify(user.email));
        await this.getBoard(user.email);
        for (let idx in this.removeBoardList) {
          // alert(this.removeBoardList[idx]);
          this.removeBoard(this.removeBoardList[idx]);
        }
        await this.getBoardReply(user.email);
        for (let idx in this.removeBoardReplyList) {
          // alert(this.removeBoardReplyList[idx]);
          this.removeBoardReply(this.removeBoardReplyList[idx]);
        }

        await auth
          .deleteUser(user)
          .then(() => {
            // User deleted.
            alert(this.$t("app_alert_success_unrgister"));

            this.logout();
          })
          .catch((error) => {
            error;
            // An error ocurred
            // ...
            alert(this.$t("app_alert_fail_unrgister"));
          });
      }
    },
    sendEmail(email) {
      // const auth = getAuth();
      alert(email);
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent!
          // ..
          alert(this.$t("app_alert_success_send_reset_email"));
          this.resetPasswordFormDialog = false;
        })
        .catch((error) => {
          error;
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // alert(errorCode, errorMessage);
          // ..
          alert(this.$t("app_alert_fail_send_reset_email"));
        });
    },
    async getVisitDayCount() {
      await axios({
        method: "GET",
        url: "/api/mainvisitdaycount" + "/?date=" + this.todayDate,
      })
        .then((response) => {
          // this.visitCount = response.data;
          // alert(JSON.stringify(response.data.results[0]));
          if (response.data.results.length < 1) {
            return this.incrementVisitDayCount("new");
          } else {
            this.visitDayCountData = response.data.results[0];
            // alert("success: " + JSON.stringify(this.visitDayCountData));
            return this.incrementVisitDayCount("update");
          }
        })
        .catch((response) => {
          console.log("axios get error: ", response);
        });
    },
    async incrementVisitDayCount(type) {
      this.visitDayCountData.date = this.todayDate;
      this.visitDayCountData.count += 1;
      // this.visitDayCountData.total_count += 1;
      if (type == "new") {
        // alert("new");
        await axios({
          method: "POST",
          url: "/api/mainvisitdaycount/",
          data: this.visitDayCountData,
        })
          .then(() => {
            // alert("추가 성공!");
            this.incrementVisitTotalCount();
          })
          .catch((error) => {
            console.log("axios post error: ", error.reponse);
            // alert("추가 실패! " + error);
          });
      } else if (type == "update") {
        // alert("update");
        await axios({
          method: "PATCH",
          url: "/api/mainvisitdaycount/" + this.visitDayCountData.id + "/",
          data: this.visitDayCountData,
        })
          .then(() => {
            // alert("업데이트 성공!");
            this.incrementVisitTotalCount();
          })
          .catch((error) => {
            console.log("axios patch error: ", error.reponse);
            // alert("업데이트 실패! " + error);
          });
      }
    },
    async getVisitTotalCount() {
      await axios({
        method: "GET",
        url: "/api/mainvisittotalcount/",
      })
        .then((response) => {
          // alert(JSON.stringify(response.data.results[0]));
          if (response.data.results.length > 0) {
            this.visitTotalCountData = response.data.results[0];
          }
        })
        .catch((error) => {
          console.log("axios get error: ", error.reponse);
          // alert("추가 실패! " + error);
        });
    },
    async incrementVisitTotalCount() {
      await this.getVisitTotalCount();

      if (this.visitTotalCountData.total_count > 0) {
        this.visitTotalCountData.total_count += 1;
        await axios({
          method: "PATCH",
          url: "/api/mainvisittotalcount/" + this.visitTotalCountData.id + "/",
          data: this.visitTotalCountData,
        })
          .then(() => {
            // alert("업데이트 성공!");
          })
          .catch((error) => {
            console.log("axios patch error: ", error.reponse);
            // alert("업데이트 실패! " + error);
          });
      } else {
        this.visitTotalCountData.total_count = this.visitDayCountData.count;
        axios({
          method: "POST",
          url: "/api/mainvisittotalcount/",
          data: this.visitTotalCountData,
        })
          .then(() => {
            // alert("추가 성공!");
          })
          .catch((error) => {
            console.log("axios post error: ", error.reponse);
            // alert("추가 실패! " + error);
          });
      }
    },
    toggleSignup() {
      this.signupFormDialog = !this.signupFormDialog;
      this.clearSignupForm();
    },
    toggleResetPW() {
      this.resetPasswordFormDialog = !this.resetPasswordFormDialog;
      this.clearResetPasswordForm();
    },

    clearChangePWForm() {
      this.changePasswordEmail = "";
      this.changePassword = "";
      this.changePassword2 = "";
      this.changePasswordType = 0;
      this.changePasswordInfo = "";
    },
    toggleChangePW() {
      this.changePasswordFormDialog = !this.changePasswordFormDialog;
      this.clearChangePWForm();
    },

    changePW(password) {
      if (this.changePasswordType == 0) {
        alert(this.$t("app_alert_check_password"));
        return;
      }

      // const auth = getAuth();
      const user = auth.currentUser;

      auth
        .updatePassword(user, password)
        .then(() => {
          // Update successful.
          alert(this.$t("app_alert_success_change_password"));
          this.logout();
        })
        .catch((error) => {
          // An error ocurred
          // ...
          error;
          alert(this.$t("app_alert_fail_change_password"));
        });
    },
    async gracefullLogout() {
      await window.Kakao.Auth.logout(() => {
        const KAKAO_CLIENT_ID = "fe7c63cb53483bfcc93f4f8dbb90cca3"; // 카카오 REST API 키
        const REDIRECT_URI = "https://conpurple.com:4001"; // 로그아웃 후 이동할 페이지

        window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${KAKAO_CLIENT_ID}&logout_redirect_uri=${encodeURIComponent(
          REDIRECT_URI
        )}`;
        this.kakaoLogout();
        this.user = {};
      });
    },
    async justLogout() {
      await window.Kakao.Auth.logout(() => {
        this.kakaoLogout();
        this.user = {};
        this.$router.go();
      });
    },
    async logout() {
      if (this.user.login_type == "kakao") {
        await this.justLogout();
        // await this.gracefullLogout();
        // if (window.Kakao.Auth.getAccessToken()) {
        //   // 계정 연결 해제 (자동 로그인 방지)
        //   await window.Kakao.API.request({
        //     url: "/v1/user/unlink",
        //     // headers: {
        //     //   "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        //     //   Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
        //     // },
        //     success: (res) => {
        //       this.kakaoLogout();
        //       this.user = {};
        //       this.$router.go();
        //       res;
        //       // alert("scuccess: " + JSON.stringify(res));
        //     },
        //     fail: (error) => {
        //       console.log("fail: " + JSON.stringify(error));
        //     },
        //   });
        // }
      } else if (this.user.login_type == "google") {
        await auth.signOut();
        this.googleLogout();
        this.$router.go();
      } else if (this.user.login_type == "normal") {
        await auth.signOut();
        this.googleLogout();
        this.$router.go();
      }
    },
    async getBoard(email) {
      await axios({
        method: "GET",
        url: "/api/mainboard/?" + "email=" + email,
      })
        .then((response) => {
          this.removeBoardList = [];
          var data = response.data;
          for (let idx = 0; idx < data.length; idx++) {
            //  alert(JSON.stringify(data[idx].id));
            this.removeBoardList.push(data[idx].id);
          }
          // alert(this.removeBoardList);
        })
        .catch((error) => {
          console.log("axios board get error: ", error.reponse);
        });
    },
    async removeBoard(id) {
      await axios({
        method: "DELETE",
        url: "/api/mainboard/" + id,
      })
        .then(() => {
          // alert("remove board success: " + id);
        })
        .catch((error) => {
          console.log("axios board del error: ", error.reponse);
        });
    },
    async getBoardReply(email) {
      await axios({
        method: "GET",
        url: "/api/mainboardreply/?" + "email=" + email,
      })
        .then((response) => {
          this.removeBoardList = [];
          var data = response.data;
          for (let idx = 0; idx < data.length; idx++) {
            //  alert(JSON.stringify(data[idx].id));
            this.removeBoardReplyList.push(data[idx].id);
          }
        })
        .catch((error) => {
          console.log("axios boardreply get error: ", error.reponse);
        });
    },
    async removeBoardReply(id) {
      await axios({
        method: "DELETE",
        url: "/api/mainboardreply/" + id,
      })
        .then(() => {
          // alert("remove board reply success: " + id);
        })
        .catch((error) => {
          console.log("axios boardreply del error: ", error.reponse);
        });
    },
    ...mapActions("loginStore", [
      "kakaoLogin",
      "kakaoLogout",
      "googleLogout",
      "normalGoogleLogin",
    ]),
  },
  computed: {
    getSelectedLanguageText() {
      const selectedLanguage = this.languages.find(
        (language) => language.value === this.$i18n.locale
      );
      return selectedLanguage ? selectedLanguage.text : "언어를 선택해주세요";
    },
    ...mapState("loginStore", ["state"]),
    ...mapGetters("loginStore", ["getUserInfo"]), // store.js의 getters를 연결해주는 helper
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    // 화면 너비
    getwidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.windowWidth * 0.9; // xs: 화면 너비의 90%
        case "sm":
          return this.windowWidth * 0.8; // sm: 화면 너비의 80%
        case "md":
          return this.windowWidth * 0.7; // md: 화면 너비의 70%
        case "lg":
          return this.windowWidth * 0.6; // lg: 화면 너비의 60%
        case "xl":
          return this.windowWidth * 0.5; // xl: 화면 너비의 50%
      }
      return this.windowWidth * 0.6;
    },
    // 화면 높이
    getheight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.windowHeight * 0.5; // xs: 화면 높이의 50%
        case "sm":
          return this.windowHeight * 0.6; // sm: 화면 높이의 60%
        case "md":
          return this.windowHeight * 0.7; // md: 화면 높이의 70%
        case "lg":
          return this.windowHeight * 0.8; // lg: 화면 높이의 80%
        case "xl":
          return this.windowHeight * 0.9; // xl: 화면 높이의 90%
      }
      return this.windowHeight * 0.8;
      // return this.$vuetify.breakpoint.height;
    },
    rules() {
      return {
        required: (value) => !!value || this.$t("app_rules_required_input"),
        required2: (value) => !!value || this.$t("app_rules_required_input"),
        email: (value) =>
          /.+@.+\..+/.test(value) || this.$t("app_rules_required_input_email"),
        minPassword: (value) =>
          value.length >= 6 || this.$t("app_rules_required_password"),
        minPassword2: (value) =>
          value.length >= 6 || this.$t("app_rules_required_password"),
      };
    },
    // 모바일 디바이스 여부 확인
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    localizedMenuItems() {
      return [
        {
          title: this.$t("app_navi_menu_introduce_board_title"),
          path: "/mainboard",
        },
      ];
    },
    localizedCompanyClippedMenuItems() {
      return [
        {
          title: this.$t("app_navi_menu_introduce_l2p_account_title"),
          icon: "mdi-account-multiple",
          path: "/company_member",
        },
        {
          title: this.$t("app_navi_menu_introduce_l2p_history_title"),
          icon: "mdi-history",
          path: "/company_history",
        },
      ];
    },
    localizedProjectClippedMenuItems() {
      return [
        {
          title: this.$t("app_navi_menu_introduce_project_android_title"),
          icon: "",
          path: "/project_android",
        },
        {
          title: this.$t("app_navi_menu_introduce_project_web_title"),
          icon: "",
          path: "/project_web",
        },
      ];
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.languageLoading = true;
      setTimeout(() => {
        this.languageLoading = false; // 1초 후 로딩 종료
        this.languageMenuVisible = false;
        localStorage.setItem("user-lang", this.$i18n.locale);
      }, 1000);
    },
    signupPassword: function () {
      if (this.signupPassword != this.signupPassword2) {
        this.signupPasswordInfo = "패스워드가 일치하지 않습니다.";
        this.signupPasswordType = 0;
      } else {
        this.signupPasswordInfo = "";
        this.signupPasswordType = 1;
      }
    },
    signupPassword2: function () {
      if (this.signupPassword != this.signupPassword2) {
        this.signupPasswordInfo = "패스워드가 일치하지 않습니다.";
        this.signupPasswordType = 0;
      } else {
        this.signupPasswordInfo = "";
        this.signupPasswordType = 1;
      }
    },
    changePassword: function () {
      if (this.changePassword != this.changePassword2) {
        this.changePasswordInfo = "패스워드가 일치하지 않습니다.";
        this.changePasswordType = 0;
      } else {
        this.changePasswordInfo = "";
        this.changePasswordType = 1;
      }
    },
    changePassword2: function () {
      if (this.changePassword != this.changePassword2) {
        this.changePasswordInfo = "패스워드가 일치하지 않습니다.";
        this.changePasswordType = 0;
      } else {
        this.changePasswordInfo = "";
        this.changePasswordType = 1;
      }
    },
    loginLoading(val) {
      val &&
        setTimeout(() => {
          this.loginLoading = false;
        }, 2000);
    },
  },
};
</script>
<style>
#app {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px auto;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  text-align: center;
}

.left-align {
  text-align: left; /* 텍스트 좌측 정렬 */
}
</style>
<style scoped>
.floating-btn-above-footer {
  position: fixed;
  bottom: 70px; /* Adjust to be just above the footer */
  right: 16px;
  z-index: 1000;
}
</style>
<!-- 
[position]
static	초기값. 기준 위치를 설정하지 않음.
relative	현재 위치를 기준으로 상대 위치를 지정.
absolute	부모 위치를 기준으로 절대 위치를 지정.
fixed	윈도우(브라우저 창)를 기준으로 절대 위치를 지정하여 요소를 그 위치에 고정(fix)시킴
sticky	지정된 위치에 뷰포트가 도달했을 때, 요소가 그 위치에 고정(fix)됨

[display]
absolute => 부모랑 분리해서 단독으로 사용
flex => 자신의 컨테이너가 차지하는 공간에 크기를 맞추기 위해 크기를 키우거나 줄이는 방법

-->

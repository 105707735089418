<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col>
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              <!-- <v-icon>keyboard_arrow_up</v-icon> -->
              위로
            </v-btn>
          </v-row>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="boardList"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :sort-by="['id', 'date']"
            :sort-desc="[true, true]"
            :search="search"
            mobile-breakpoint="0"
            class="elevation-1 pt-3 p"
            @page-count="pageCount = $event"
            style="cursor: pointer"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:header="{}">
              <th
                style="font-size: 18px"
                v-for="head in headers"
                v-bind:key="head.text"
              >
                {{ head.text.toUpperCase() }}
                <hr />
              </th>
            </template>
            <template v-slot:item="{ item }">
              <tr style="text-align: center" @click="rowClick(item)">
                <td>{{ calcCurCount(boardList.indexOf(item)) }}</td>
                <td>{{ item.mbtitype }}</td>
                <td>{{ item.subject }} [{{ getCountReply(item.id) }}]</td>
                <td>{{ item.date }}</td>
              </tr>
            </template>
          </v-data-table>

          <v-row justify="center">
            <v-spacer></v-spacer>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                clearable
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="pink"
                    dark
                    class="mt-3 mr-3"
                    @click="clickWriteBtn()"
                    >글쓰기</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">MBTI 게시판 글쓰기</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-autocomplete
                            :items="items"
                            :rules="selectrules"
                            :search-input.sync="writeData.mbtitype"
                            v-model="writeData.mbtitype"
                            label="MBTI 타입"
                            outlined
                            required
                          ></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="5" sm="5" offset-md="2">
                          <v-text-field
                            v-model="getUser"
                            :readonly="isLoggedIn"
                            :counter="10"
                            :rules="namerules"
                            label="사용자"
                            required
                          ></v-text-field>
                        </v-col> -->
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="writeData.subject"
                            :counter="20"
                            :rules="subjectrules"
                            label="제목"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="writeData.content"
                            :counter="200"
                            :rules="contentrules"
                            label="내용"
                            outlined
                            required
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="green lighten-1 ml-1"
                      outlined
                      rounded
                      text
                      @click="writeBoardDialog = true"
                      :disabled="writeBoardDialog"
                      :loading="writeBoardDialog"
                      >쓰기
                    </v-btn>

                    <v-btn
                      class="deep-purple lighten-1 ml-1"
                      outlined
                      rounded
                      text
                      @click="closeDialog"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="writeBoardDialog"
                hide-overlay
                persistent
                width="300"
              >
                <v-card color="green" dark>
                  <v-card-text>
                    Writting...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-pagination
            v-model="page"
            :length="pageInfo.pageCount"
            @input="clickPageNum(page)"
          ></v-pagination>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";
let boardurl = "api/mbtiboard/";
let repurl = "api/mbtiboardreply/";
import { mapGetters, mapState } from "vuex";

export default {
  name: "MbtiBoard",
  data: () => ({
    fab: false,
    search: "",
    isDescending: true,
    page: 1,
    singleExpand: true,
    itemsPerPage: 10,
    expanded: [],
    boardList: [],
    boardReplyList: [],
    getUser: "",
    pageInfo: {
      totalCount: 0,
      curPage: 0,
      pageCount: 0,
      // next: "",
      // prev: "",
    },
    headers: [
      {
        text: "N",
        align: "start",
        sortable: false,
      },
      {
        text: "타입",
        align: "start",
        sortable: false,
        value: "mbtitype",
      },
      //   { text: 'MBTI TYPE', value: 'mbtitype', sortable: false },
      { text: "제목", value: "subject", sortable: false },
      //   { text: '내용', value: 'content', sortable: false  },
      //   { text: '이름', value: 'username', sortable: false  },
      //   { text: '조회수', value: 'count', sortable: false  },
      { text: "날짜", value: "date", sortable: false },
    ],
    dialog: false,
    writeBoardDialog: false,
    items: [
      "INFP",
      "ENFP",
      "INFJ",
      "ENFJ",
      "INTJ",
      "ENTJ",
      "INTP",
      "ENTP",
      "ISFP",
      "ESFP",
      "ISTP",
      "ESTP",
      "ISFJ",
      "ESFJ",
      "ISTJ",
      "ESTJ",
    ],
    enabled: false,
    patchIncHitcountData: {
      hitcount: "",
    },
    writeData: {
      mbtitype: "",
      subject: "",
      content: "",
      username: "",
      date: "",
    },
    namerules: [
      (v) => !!v || "사용자 이름은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "사용자 이름은 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "사용자 이름에는 특수문자를 사용할 수 없습니다.",
    ],
    subjectrules: [
      (v) => !!v || "제목은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 20) || "제목은 20자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    contentrules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 200) || "내용은 200자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    selectrules: [(v) => !!v || "MBTI 타입은 필수 입력사항 입니다."],
    passwordrules: [
      (v) => !!v || "패스워드는 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "패스워드는 10자 이상 입력할 수 없습니다.",
    ],
  }),
  // props: ["propsdata"],
  watch: {
    writeBoardDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.writeForm(), (this.writeBoardDialog = false)),
        1000
      );
    },
    dialog(val) {
      if (!val) return;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.getUser = this.getUserInfo.userid;
    }
  },
  computed: {
    ...mapState("mbtingStore", ["isLoggedIn", "user"]),
    ...mapGetters("mbtingStore", ["isLogin", "getUserInfo"]),
  },
  mounted() {
    this.$vuetify.goTo(0);
    this.getBoardList(1);
    this.getBoardReplyList();
  },
  methods: {
    calcCurCount: function (index) {
      return (
        this.pageInfo.totalCount -
        (this.pageInfo.curPage - 1) * this.itemsPerPage -
        index
      );
    },
    clickPageNum: function (page) {
      this.getBoardList(page);
    },
    async getBoardList(page) {
      axios({
        method: "GET",
        url: boardurl + "?page=" + page,
      })
        .then((response) => {
          this.pageInfo.totalCount = response.data.count;
          this.pageInfo.pageCount = Math.ceil(
            this.pageInfo.totalCount / Number(this.itemsPerPage)
          );
          this.pageInfo.curPage = page;

          if (this.boardList.length > 0) {
            this.boardList = [];
          }
          this.boardList = response.data.results;
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return 0;
        });
    },
    async getBoardReplyList() {
      await axios({
        method: "GET",
        url: repurl,
      })
        .then((response) => {
          this.boardReplyList = response.data.results;
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return 0;
        });
    },
    clickWriteBtn() {
      this.checkAuth()
        .then((ret) => {
          if (!ret) {
            this.dialog = false;
            alert("로그인 후 글쓰기가 가능합니다.");
            return;
          } else {
            this.dialog = true;
          }
        })
        .catch((err) => console.log(err));
    },
    async checkAuth() {
      return await this.isLoggedIn;
    },
    writeForm: function () {
      if (this.isLoggedIn) {
        this.writeData.username = this.getUser;
      }
      
      if (
        this.writeData.mbtitype == "" ||
        this.writeData.subject == "" ||
        this.writeData.content == "" ||
        this.writeData.username == ""
      ) {
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();

      this.writeData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;
      this.dialog = false;

      axios({
        method: "POST",
        url: boardurl,
        data: this.writeData,
      })
        .then(() => {
          this.getBoardList(1);
          // this.boardList = response.data;
          // this.boardList = response.data.results;
          // this.$emit("saved");
          /* 초기화 */
          this.writeData.mbtitype = "";
          this.writeData.subject = "";
          this.writeData.content = "";
          this.writeData.username = "";
          this.writeData.date = "";
        })
        .catch((error) => {
          console.log("Axios get error: ", error.reponse);
        });
    },
    rowClick: function (row) {
      this.incHitCount(row.id);
      this.$router.push({ name: "MbtiBoardDetail", params: { id: row.id } });
    },
    closeDialog: function () {
      if (confirm("입력한 내용이 지워집니다. 창을 닫으시겠습니까?")) {
        this.writeData.mbtitype = "";
        this.writeData.subject = "";
        this.writeData.content = "";
        this.writeData.username = "";
        this.writeData.date = "";
        this.dialog = false;
      }
    },
    getCountReply: function (id) {
      let count = 0;
      for (let idx in this.boardReplyList) {
        if (this.boardReplyList[idx].board_id != id) {
          continue;
        }
        count = count + 1;
      }
      return count;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    incHitCount: function (id) {
      for (let idx in this.boardList) {
        if (this.boardList[idx].id != id) {
          continue;
        }
        if (id == this.boardList[idx].id) {
          var count = Number(this.boardList[idx].hitcount);
          count++;
          this.patchIncHitcountData.hitcount = count;
          break;
        }
      }

      axios.defaults.xsrfCookieName = "csrftoken";
      axios.defaults.xsrfHeaderName = "X-CSRFToken";
      axios.defaults.withCredentials = true;
      axios({
        method: "PATCH",
        url: "/api/board/" + id + "/",
        // data: JSON.stringify(this.patchIncHitcountData),
        data: this.patchIncHitcountData,
      })
        .then((response) => {
          console.log(response);
          // this.getList(this.id);
        })
        .catch((error) => {
          console.log("Axios patch error: ", error.reponse);
        });
    },
  },
};
</script>

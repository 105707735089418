var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mt-2"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_vm._v(" 위로 ")])],1)],1),_c('v-card',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',{staticClass:"\n              my-4\n              text-h4 text-center\n              blue-grey--text\n              text--blue-grey\n              darken-1\n            "},[_vm._v("아래 타입을 클릭해보세요!")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"\n              background-color:\n              transparent\n              text-center\n              outline\n              blue--text\n            ",attrs:{"ripple":false,"icon":"","href":"https://www.16personalities.com/ko/%EB%AC%B4%EB%A3%8C-%EC%84%B1%EA%B2%A9-%EC%9C%A0%ED%98%95-%EA%B2%80%EC%82%AC","target":"_blank","color":"deep-orange"}},[_c('v-icon',[_vm._v("mdi-launch")]),_vm._v("검사하러 가기 ")],1)],1),_c('v-item-group',{attrs:{"active-class":"primary"}},[_c('v-container',{attrs:{"mt-3":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedE),callback:function ($$v) {_vm.selectedE=$$v},expression:"selectedE"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleE(true)}}},[_vm._v(" E ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleE(false)}}},[_vm._v(" E: 외향적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 폭 넓은 대인관계를 유지하며 사교적이며 정열적이고 활동적임. ")])])])],1)],1)]}}])})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedI),callback:function ($$v) {_vm.selectedI=$$v},expression:"selectedI"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleI(true)}}},[_vm._v(" I ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleI(false)}}},[_vm._v(" I: 내향적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 깊이있는 대인관계를 유지하며 조용하고 신중하며 이해한 다음에 경험함. ")])])])],1)],1)]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-item-group',{attrs:{"active-class":"primary"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedS),callback:function ($$v) {_vm.selectedS=$$v},expression:"selectedS"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleS(true)}}},[_vm._v(" S ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleS(false)}}},[_vm._v(" S: 감각적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 오감에 의존하여 실제의 경험을 중시하고, 현재에 초점을 맞추어 일처리함. ")])])])],1)],1)]}}])})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedN),callback:function ($$v) {_vm.selectedN=$$v},expression:"selectedN"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleN(true)}}},[_vm._v(" N ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleN(false)}}},[_vm._v(" N: 직관적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 육감 내지 영감에 의존하며 미래지향적이고 가능성을 추구함. ")])])])],1)],1)]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-item-group',{attrs:{"active-class":"primary"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedT),callback:function ($$v) {_vm.selectedT=$$v},expression:"selectedT"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleT(true)}}},[_vm._v(" T ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleT(false)}}},[_vm._v(" T: 사고적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 진실과 사실에 주관심을 갖고 논리적이고 분석적이며 객관적으로 판단함. ")])])])],1)],1)]}}])})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedF),callback:function ($$v) {_vm.selectedF=$$v},expression:"selectedF"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleF(true)}}},[_vm._v(" F ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleF(false)}}},[_vm._v(" F: 감정적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 사람과 관계에 주관심을 갖고 상황적이며 정상을 참작한 설명함. ")])])])],1)],1)]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-item-group',{attrs:{"active-class":"primary"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedJ),callback:function ($$v) {_vm.selectedJ=$$v},expression:"selectedJ"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleJ(true)}}},[_vm._v(" J ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleJ(false)}}},[_vm._v(" J: 판단적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 분명한 목적과 방향이 있으며 기한을 엄수하고 철저히 사전 계획하고 체계적임. ")])])])],1)],1)]}}])})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"dark":"","height":"100"},on:{"click":toggle},model:{value:(_vm.selectedP),callback:function ($$v) {_vm.selectedP=$$v},expression:"selectedP"}},[_c('v-card-text',[_c('v-scroll-y-transition',[(!active)?_c('div',{staticClass:"text-h2 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleP(true)}}},[_vm._v(" P ")]):_c('div',{staticClass:"pt-3 text-h6 flex-grow-1 text-center",on:{"click":function($event){return _vm.toggleP(false)}}},[_vm._v(" P: 인식적 "),_c('br'),_c('p',{staticClass:"text-caption"},[_vm._v(" 목적과 방향은 가능하고 상황에 따라 일정이 달라지며 자율적이고 융통성 있음. ")])])])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
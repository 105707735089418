<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col>
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              <!-- <v-icon>keyboard_arrow_up</v-icon> -->
              위로
            </v-btn>
          </v-row>
        </v-card>

        <v-card v-if="this.paramUser">
          <v-row justify="center">
            <v-card-text
              class="my-4 text-h5 text-center blue-grey--text text--blue-grey darken-1"
              >내가 생각하는 친구의 MBTI 타입을 맞혀보세요!</v-card-text
            >
          </v-row>
          <v-row justify="center">
            <v-col cols="6">
              <v-autocomplete
                :items="items"
                :rules="selectrules"
                :search-input.sync="seledtedMbtitype"
                v-model="seledtedMbtitype"
                label="MBTI 타입"
                outlined
                required
                prepend-inner-icon="mdi-heart"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-3">
            <!-- <div class="d-flex"> -->
            <v-col cols="6">
              <v-text-field
                v-model="myname"
                :counter="10"
                :rules="namerules"
                label="나의 이름"
                clearable
                outlined
                required
                prepend-inner-icon="mdi-account-outline"
                style="width: 30"
              ></v-text-field>
            </v-col>
            <!-- </div> -->
          </v-row>
          <v-row justify="center">
            <!-- <div class="d-flex"> -->
            <v-col cols="6">
              <v-textarea
                v-model="comment"
                label="코멘트"
                outlined
                clearable
                type="text"
                filled
                :counter="40"
                :rules="commentrules"
                required
              ></v-textarea>
            </v-col>
            <!-- </div> -->
          </v-row>
          <v-row justify="center">
            <v-btn
              outlined
              rounded
              text
              class="ml-2 mt-2 mb-2"
              @click="answerBtnDialog = true"
              :disabled="answerBtnDialog"
              :loading="answerBtnDialog"
            >
              제출 하기
            </v-btn>
          </v-row>
          <!-- <v-divider></v-divider> -->
        </v-card>
        <v-dialog v-model="loadingDialog" persistent width="300">
          <v-card color="white">
            <v-card-text>
              Waiting...
              <v-progress-linear
                indeterminate
                color="black"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="answerBtnDialog" persistent width="300">
          <v-card color="white">
            <v-card-text>
              Waiting...
              <v-progress-linear
                indeterminate
                color="black"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";
let guessmeurl = "https://conpurple.com/api/guessme/";
let logincheckurl = "https://conpurple.com/api/logincheck/"

export default {
  name: "MbtiGuessmeAnswer",
  data: () => ({
    items: [
      "INFP",
      "ENFP",
      "INFJ",
      "ENFJ",
      "INTJ",
      "ENTJ",
      "INTP",
      "ENTP",
      "ISFP",
      "ESFP",
      "ISTP",
      "ESTP",
      "ISFJ",
      "ESFJ",
      "ISTJ",
      "ESTJ",
    ],
    fab: false,
    paramUser: "",
    loadingDialog: false,
    answerBtnDialog: false,
    selectedInfo: {
      selectedENFP: false,
      selectedENFJ: false,
      selectedENTJ: false,
      selectedENTP: false,

      selectedESFP: false,
      selectedESFJ: false,
      selectedESTJ: false,
      selectedESTP: false,

      selectedINFP: false,
      selectedINFJ: false,
      selectedINTJ: false,
      selectedINTP: false,

      selectedISFP: false,
      selectedISFJ: false,
      selectedISTJ: false,
      selectedISTP: false,
    },
    senderMbtitype: "",
    seledtedMbtitype: "",
    answerInfo: {
      userid: "",
      myname: "",
      mbtitype: "",
      comment: "",
      date: "",
    },
    myname: "",
    comment: "",
    namerules: [
      (v) => !!v || "사용자 이름은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "사용자 이름은 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "사용자 이름에는 특수문자를 사용할 수 없습니다.",
    ],
    commentrules: [
      (v) => !(v && v.length >= 40) || "코멘트는 40자 이상 입력할 수 없습니다.",
    ],
    selectrules: [(v) => !!v || "MBTI 타입은 필수 입력사항 입니다."],
  }),
  watch: {
    answerBtnDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.answerBtnForm(), (this.answerBtnDialog = false)),
        1000
      );
    },
  },
  computed: {},
  mounted() {
    if (this.$route.params.userid != undefined) {
      this.paramUser = this.$route.params.userid;
      this.getSenderInfo(this.paramUser);
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    async getSenderInfo() {
      await axios({
        method: "GET",
        url: logincheckurl + "?userid=" + this.paramUser,
      })
        .then((response) => {
          // alert(response.data.items[0].fields.mbtitype)
          this.senderMbtitype = response.data.items[0].fields.mbtitype;
        })
        .catch((response) => {
          // alert('fail')
          console.log("axios error: ", response);
          return 0;
        });
    },
    answerBtnForm: function () {
      this.answerInfo.userid = this.$route.params.userid;
      this.answerInfo.myname = this.myname;
      this.answerInfo.mbtitype = this.seledtedMbtitype;
      this.answerInfo.comment = this.comment;
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();
      this.answerInfo.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "POST",
        url: guessmeurl,
        data: this.answerInfo,
      })
        .then((response) => {
          console.log(
            "send: " +
              this.senderMbtitype +
              ", answer: " +
              this.seledtedMbtitype
          );
          if (this.senderMbtitype != this.seledtedMbtitype) {
            alert("땡!!");
            this.$router.go("");
          } else {
            alert("오~ 맞췄습니다!!");
            this.$router.go("");
          }
          this.answerInfo.userid = "";
          this.answerInfo.mbtitype = "";
          this.answerInfo.myname = "";
          this.answerInfo.comment = "";
          this.answerInfo.date = "";
          console.log("success made: " + JSON.stringify(response.data));
        })
        .catch((error) => {
          alert("제출 실패 하였습니다.");
          console.log("axios post error: ", error.reponse);
        });
    },
  },
};
</script>

<template>
  <v-container fluid>
    <div class="d-flex mb-5 justify-center">
      <h1>{{ $t("maincompannyhistory_container_title") }}</h1>
    </div>
    <div>
      <v-timeline align="start" dense>
        <v-timeline-item
          v-for="(item, index) in localizedHistories"
          :key="index"
          :dot-color="item.color"
          size="small"
          right
        >
          <template>
            <div
              :class="`pt-1 headline font-weight-bold text-${item.color}`"
              v-text="item.year"
            ></div>
            <v-sheet
              class="d-flex align-baseline"
              v-for="content in item.contents"
              :key="content.id"
            >
              <v-sheet>
                <p class="mt-2 ms-2" style="font-size: 18px">
                  {{ content.month }}
                </p>
                <p v-if="content.link" style="font-size: 12px">
                  <v-icon>mdi-circle-small</v-icon>
                  <a
                    class="text-black text-decoration-none"
                    :href="`${content.link}`"
                    rel="noopener noreferrer"
                    target="_blank"
                    >{{ content.title }}</a
                  >
                </p>
                <p v-else style="font-size: 12px">
                  <v-icon>mdi-circle-small</v-icon>{{ content.title }}
                </p>
              </v-sheet>
            </v-sheet>
          </template>
        </v-timeline-item>
      </v-timeline>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    localizedHistories() {
      return [
        {
          id: 1,
          year: "2023" + this.$t("maincompannyhistory_year_title"),
          color: "black",
          filldot: true,
          contents: [
            {
              month: "10" + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2023_0_title"),
              desc: "",
              link: "",
            },
            {
              month: "05" + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2023_1_title"),
              desc: "",
              link: "https://play.google.com/store/apps/details?id=com.l2p.android.wonderpods&pcampaignid=web_share",
              // "/project_android",
            },
            {
              month: "05 " + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2023_2_title"),
              desc: "",
              link: "",
            },
          ],
        },
        {
          id: 2,
          year: "2021" + this.$t("maincompannyhistory_year_title"),
          color: "black",
          filldot: true,
          contents: [
            {
              month: "09" + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2021_0_title"),
              desc: "",
              link: "https://play.google.com/store/apps/details?id=com.keonwoo.android.alldroid&pcampaignid=web_share",
            },
          ],
        },
        {
          id: 3,
          year: "2019" + this.$t("maincompannyhistory_year_title"),
          color: "black",
          filldot: true,
          contents: [
            {
              month: "05" + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2019_0_title"),
              desc: "",
              link: "https://play.google.com/store/apps/details?id=com.xiadroid.android.xiadroid&pcampaignid=web_share",
            },
            {
              month: "04" + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2019_1_title"),
              desc: "",
              link: "https://play.google.com/store/apps/details?id=com.qcdroid.android.qcdroid&pcampaignid=web_share",
            },
            {
              month: "01" + this.$t("maincompannyhistory_month_title"),
              color: "black",
              title: this.$t("maincompannyhistory_2019_2_title"),
              desc: "",
              link: "",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style>
</style>

<template>
  <v-container>
    <div style="width: 100%; height: 100%">
      <v-img
        v-if="user.is_logined == false"
        :src="require('@/assets/kakao_login_medium_wide.png')"
        style="
          width: 100%;
          height: 100%;
          cursor: pointer;
          display: block;
          margin: 0 auto;
        "
        contain
        @click="login()"
      ></v-img>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      user: {}, // TODO store로 이관 필요
    };
  },
  created() {
    this.user = this.getUserInfo;
    // alert(JSON.stringify(this.user));
  },
  mounted() {
window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters("loginStore", ["getUserInfo"]), // store.js의 getters를 연결해주는 helper
  },
  methods: {
    async login() {
      await window.Kakao.Auth.login({
        scope: "profile_nickname, account_email",
        success: this.getKakaoAccount,
      });
    },
    getKakaoAccount() {
      window.Kakao.API.request({
        url: "/v2/user/me",
        success: (res) => {
          const access_token = window.Kakao.Auth.getAccessToken();
          const kakao_account = res.kakao_account;
          const userInfo = {
            name: kakao_account.profile.ninkname,
            email: kakao_account.email,
          };
          window.Kakao.Auth.setAccessToken(access_token);
          this.kakaoLogin(userInfo);
          this.$router.go();
        },
        fail: (error) => {
          console.log(error);
        },
      });
    },
    ...mapActions("loginStore", ["kakaoLogin"]),
  },
};
</script>

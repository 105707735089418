<template>
  <v-container fluid>
    <div class="d-flex mb-5 justify-center">
      <h1>{{ $t("maincompannymember_container_title") }}</h1>
    </div>
    <v-card
      class="mb-3"
      v-for="member in localizedMembers"
      :key="member.key"
      outlined
    >
      <v-list-item>
        <v-avatar>
          <v-img :src="member.image" @click="toggleFullscreen(member.image)">
          </v-img>
        </v-avatar>
        <v-list class="ms-3">
          <v-list-item-title>
            <p class="mt-3" style="font-size: 18px">
              {{ member.name }}({{ member.major }})
            </p>
            <!-- <p style="font-size: 14px">{{ member.major }}</p> -->
          </v-list-item-title>
          <!-- <v-list-item-subtitle><p style="font-size: 14px;">{{ member.major }}</p></v-list-item-subtitle> -->
        </v-list>
        <!-- </v-list-item-content> -->
      </v-list-item>

      <v-list-item>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="mt-3">{{
                member.content
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-icon
              v-if="member.instagram_url != ''"
              @click="instaClick(member.instagram_url)"
              size="30"
              >mdi-instagram</v-icon
            >
            <v-icon @click="blogClick(member.blog_url)" size="30"
              >mdi-alpha-b-box-outline</v-icon
            >
          </v-list-item>
        </v-list>
      </v-list-item>
    </v-card>
    <v-divider></v-divider>
    <v-dialog v-model="fullscreenImageDialog" fullscreen>
      <v-card class="fullscreen-card" flat>
        <v-img
          :src="fullscreenImage"
          class="fullscreen-img"
          width="300vh"
          height="100vh"
          contain
          @click="closeFullscreen()"
        ></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    fullscreenImage: 'null', // 클릭한 이미지 저장
    fullscreenImageDialog: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  }),
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    instaClick: function (insta) {
      // alert(insta);
      if (insta != null) {
        window.open(insta);
      }
    },
    blogClick: function (blog) {
      // alert(blog);
      if (blog != null) {
        window.open(blog);
      }
    },
    toggleFullscreen(image) {
      this.fullscreenImage = image;
      this.fullscreenImageDialog = true;
    },
    closeFullscreen() {
      this.fullscreenImage = null;
      this.fullscreenImageDialog = false;
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    updateHeight() {
      this.windowHeight = window.innerWidth;
    },
  },
  computed: {
    getwidth() {
      // alert(this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.windowWidth * 0.9; // xs: 화면 너비의 90%, 모바일
        case "sm":
          return this.windowWidth * 0.8; // sm: 화면 너비의 80%
        case "md":
          return this.windowWidth * 0.7; // md: 화면 너비의 70%, 테블릿
        case "lg":
          return this.windowWidth * 0.6; // lg: 화면 너비의 60%, 노트북
        case "xl":
          return this.windowWidth * 0.5; // xl: 화면 너비의 50%, 데스크탑
      }
      return this.windowWidth * 0.6;
    },
    // 화면 높이
    getheight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.windowHeight * 0.5; // xs: 화면 높이의 50%
        case "sm":
          return this.windowHeight * 0.6; // sm: 화면 높이의 60%
        case "md":
          return this.windowHeight * 0.7; // md: 화면 높이의 70%
        case "lg":
          return this.windowHeight * 0.8; // lg: 화면 높이의 80%
        case "xl":
          return this.windowHeight * 0.9; // xl: 화면 높이의 90%
      }
      return this.windowHeight * 0.8;
      // return this.$vuetify.breakpoint.height;
    },
    localizedMembers() {
      return [
        {
          key: 1,
          image: "/images/cglee.png",
          name: this.$t("maincompannymember_member_0_name"),
          major: this.$t("maincompannymember_member_0_major"),
          content: this.$t("maincompannymember_member_0_content"),
          // excerpt: "L2P 많이 응원해주세용 ㅎㅎ",
          instagram_url: "",
          // instagram_url: "https://www.instagram.com/changgeun_89",
          blog_url: "https://blog.naver.com/devlee89",
        },
        {
          key: 2,
          image: "/images/gwlee2.png",
          name: this.$t("maincompannymember_member_1_name"),
          major: this.$t("maincompannymember_member_1_major"),
          content: this.$t("maincompannymember_member_1_content"),
          // excerpt: "L2P 많이 응원해주세용 ㅎㅎ",
          instagram_url: "https://www.instagram.com/kunulee",
          blog_url: "https://blog.naver.com/kunukunu",
        },
        {
          key: 3,
          image: "/images/djpark.png",
          name: this.$t("maincompannymember_member_2_name"),
          major: this.$t("maincompannymember_member_2_major"),
          content: this.$t("maincompannymember_member_2_content"),
          // excerpt: "L2P 많이 응원해주세용 ㅎㅎ",
          instagram_url: "https://www.instagram.com/djpark386/",
          blog_url: "https://blog.naver.com/l2pcom",
        },
      ];
    },
  },
};
</script>

<style>
.custom-avatar {
  cursor: zoom-in;
}

.member-img {
  cursor: pointer;
  transition: transform 0.3s;
}
.member-img:hover {
  transform: scale(1.05);
}

/* 다이얼로그 전체 화면 스타일 */
.fullscreen-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black; /* 배경 검은색 */
  width: 100vw;
  height: 100vh;
}

/* 전체 화면 이미지 스타일 */
.fullscreen-img {
  width: 100vw; /* 화면 전체 너비 */
  height: 100vh; /* 화면 전체 높이 */
  object-fit: contain; /* 이미지 비율 유지하며 화면에 맞춤 */
  cursor: pointer;
}
</style>

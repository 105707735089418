<template>
  <div>
    <v-container>
      <div class="d-flex mb-5 justify-center">
        <h1>{{ $t("mainprojectandroid_container_name") }}</h1>
      </div>
      <v-card
        v-for="(item, index) in localizedAndroidProjects"
        class="mx-auto pa-10 ma-12"
        max-width="600px"
        :id="`card-${item.idx}`"
        :key="item.idx"
        outlined
      >
        <v-img
          :src="item.icon"
          style="
            width: 60px;
            height: 60px;
            cursor: pointer;
            display: block;
            margin: 0 auto;
          "
          @click="gotoAnd(index)"
        ></v-img>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-h5 mb-4">
              {{ item.title }}
            </div>
            <v-list-item-title class="text-h7 mb-1">
              {{ item.subtitle }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="justify-center">
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="gotoAnd(index)"
          >
            Download
          </v-btn>
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="openDetails(index)"
          >
            details
          </v-btn>
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="openFaqs(index)"
          >
            FAQ
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog
        v-model="detailDialog"
        max-width="550"
        max-height="700"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-overlay v-model="detailLoadingDialog">
            <div v-if="detailLoadingDialog">
              <v-progress-circular
                indeterminate
                color="gray"
              ></v-progress-circular>
            </div>
          </v-overlay>
          <v-card-title class="sticky-title">
            <span>{{ this.detailDialogName }}</span>
            <v-spacer></v-spacer>
            <v-icon @click="closeDetails()">mdi-close </v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="font-weight-bold text-grey-darken-1">
            <div v-html="formattedDetailsContent" class="detail-content"></div>
          </v-card-text>
          <v-divider class="mb-2"></v-divider>
          <v-card-text><b>Preview</b></v-card-text>
          <v-carousel show-arrows="hover" cycle>
            <v-carousel-item
              v-for="(item, index) in detailDialogItems"
              :key="index"
            >
              <v-img :src="item" height="400px" contain></v-img
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="faqDialog"
        max-width="550"
        max-height="700"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title class="sticky-title">
            <span>{{ this.faqDialogtitle }}</span>
            <v-spacer></v-spacer>
            <v-icon @click="closeFaqs()">mdi-close </v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            class="pa-0 font-weight-bold text-grey-darken-1 text-left"
            v-for="(item, index) in this.faqDialogList"
            :key="index"
          >
            <v-btn class="wrap-btn" text @click="toggleQuestion(item.id)">
              <span
                >{{ item.question }}
                <v-icon class="ms-1"
                  >mdi-arrow-down-bold-box-outline</v-icon
                ></span
              >
            </v-btn>
            <v-expand-transition
              @after-leave="scrollToExpanded"
              @after-enter="scrollToExpanded"
            >
              <v-card-text class="font-weight-bold text-grey-darken-1">
                <div
                  v-if="expandedAnswer === item.id"
                  ref="expandedSection"
                  v-html="formattedFaqsAnswer(item.answer)"
                  class="faq-content"
                ></div>
              </v-card-text>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    slide: 0, // 현재 선택된 슬라이드 인덱스
    wonderpodsItems: [
      "https://play-lh.googleusercontent.com/zpJ0N9bMGTn5SFlYbW_rbrkVPGc0_fGey7x9PtPpifm1xnSE7eRorXNP8zt1iL0D-Q=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/ThVEMS4NUuSSfwhKVT85i2AtrqrkROpZbbn_VbpYQr8nBv9BGy_tJBkBLSnko8Egru0=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/8D5mwLLNYZSnjuHm56_f8dB8iiv50237RSQChDgxB2QjkXHg2GH0bsALknCpC4GZyHk=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/8tQGSjxG2VjShpluGOyDA5nKjtRVXxgtmuJ-C3y8jSCTpEctAjOVJm7IiigOKvT3Nw=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/sBcFErrrcXPLgxtZuiW6hOb0XNzvK4rgNVbvQX_5aKuZTYRITm-XE5PfLonINYJ6utQ=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/PSOlcX3SHI2C9rND_DkF0VvfWFizdhmudkGJeV7GSLVvpW6epiAkAN7UifZpOtTPxvg=w5120-h2880-rw",
    ],
    alldroidItems: [
      "https://play-lh.googleusercontent.com/g8tD-nBn2-v10DkWqs39WPzCSzQG8dXGAkgQlK8hAF3MAlkAuJ5pFEJr_waME0KkQw=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/mK7fyUzI59Eh0uch4VOY0Axfu0nw_7l4V_XTDyUU8IXU9JA6q4sly5fyPoYJJRid3z8=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/swmyrlhdoGBOz-EufeRklGYNS8L5VGIezsFsUtFXgC-aU0STGAQIdqdLyC9oxx9DDg=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/mbOR-Ee1hhna_eTQvgDy3dKUSCZ3rsPkOW2KkVBRGkoYR4VVlZXk19mOg5gRSef8IhIj=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/UfZcvSEa_RsiVyQ_HQeGPEq5hfujzNJxdbueiXEP9rsQkWLto0fyd6s1GupUe05xDlw=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/kfirUSwQ-wnfbh2tROtZZFD0xgENUBsmLdmVipUCyXyGSUwwlkh8gZaF5SwbkDOeSQ=w5120-h2880-rw",
    ],
    dotdroidItems: [
      "https://play-lh.googleusercontent.com/L6_W4xIfRiXRlwCL1vsnVXeaJCikLv2b335VwpBFjMrUo4Pg2zfm6U_SXEs4EiT3KCw=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/Ikx4iCHCuMuBr1ABPnx79OpBdM6pnS7KUpmE7Gmb7cvSoQvBWXSbxK7wjAtU44LOLlna=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/zZHy61nSRtK4Yr_AUhbIKQFdWFZ8pKF34t5Ll7YuU4rXuOXuNf8kIP85utHGECv4aSQ=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/oQrEvBXoN38ny-nZaDb7KSwf3bOv_rmMVmjhhZ1FsrJHyf0nyATP1TqUL43XsjZVgA=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/raoQpdNQfa3nNPcENECciWdATE4n0g5fEFhyTJ3qEIN5-vSIDSqM9CFBM0O-p8JUuY4U=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/P6yd3rjo8fidkpdYNnRUJ6E-9ct0Hks6q0eiRt7a0MoTJ-Oks7S0FXRY1XoI9KD4KWs=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/voVnw_4qn_DZvtBZxEtXSMDwVoeMnJ70a-S5ilNqyf7lX6wu0TuF3ZXJPfXLkzXAFqk=w5120-h2880-rw",
    ],
    qdroidItems: [
      "https://play-lh.googleusercontent.com/2_e_6yRNK2UZafTVr2IWXxBzNVgjuWLwP1WWaXc3PhzuvCxa0ZLjp0S_sZ30BSuFuzc=w1052-h592-rw",
      "https://play-lh.googleusercontent.com/IMFEe0rz6zmziJpKpaiZDyu-drse4pYwEo5c-Q1UtpFuxMDpHAlXuko028PIndKw6cQ=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/pFL8k0iK1ejELmGPZjjZBfa2JZ1FraLkTvxOwpBCvnhjS2Y_XTQuAioRnlwL3bTgZXU=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/4Q1iGrE7HOhVuMr9yWJ-2DGo7D605r9JF-VS49eb9j7ApJ-Tmal_MWA1z_C45lcadYc=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/fbpWMLHk33OWfRSUhaxcULUAR32w_wUdooOGuqI7tpgPlxdzlHL7Gb2ooDmzUhNn1A=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/jvYfiu5vjl30qI8u0bNSsHQzvJJj3haeA2sS84UzZtkQrBi-FyaNpnAcYeUlXo5RYg=w5120-h2880-rw",
      "https://play-lh.googleusercontent.com/klj5j3eQBrK3l8s1ASEH8aG25QudPm4aRFdUB9XC6k0KfXM85F8ZO-0-3uWi9MHMISE=w5120-h2880-rw",
    ],
    expandedAnswer: null,
    faqDialogtitle: "",
    detailDialogName: "",
    detailDialogItems: [],
    detailDialogContents: "",
    detailDialog: false,
    detailLoadingDialog: false,
    faqDialogList: [],
    faqDialog: false,
  }),
  methods: {
    gotoAnd: function (index) {
      // alert("goto web" + index);
      if (index == 0) {
        window.open(
          "https://play.google.com/store/apps/details?id=com.l2p.android.wonderpods"
        );
      } else if (index == 1) {
        window.open("https://play.google.com/store/search?q=alldroid&c=apps");
      } else if (index == 2) {
        window.open("https://play.google.com/store/search?q=dotdroid&c=apps");
      } else if (index == 3) {
        window.open(
          "https://play.google.com/store/apps/details?id=com.qcdroid.android.qcdroid"
        );
      }
    },
    closeDetails() {
      this.detailDialog = false;
    },
    openDetails(index) {
      this.detailDialog = true;
      if (index == 0) {
        this.detailDialogName = this.$t(
          "mainprojectandroid_wonderpod_details_title"
        );
        this.detailDialogItems = this.wonderpodsItems;
        this.detailDialogContents = this.$t(
          "mainprojectandroid_wonderpod_details"
        );
      } else if (index == 1) {
        this.detailDialogName = this.$t(
          "mainprojectandroid_alldroid_details_title"
        );
        this.detailDialogItems = this.alldroidItems;
        this.detailDialogContents = this.$t(
          "mainprojectandroid_alldroid_details"
        );
      } else if (index == 2) {
        this.detailDialogName = this.$t(
          "mainprojectandroid_dotdroid_details_title"
        );
        this.detailDialogItems = this.dotdroidItems;
        this.detailDialogContents = this.$t(
          "mainprojectandroid_dotdroid_details"
        );
      } else if (index == 3) {
        this.detailDialogName = this.$t(
          "mainprojectandroid_qdroid_details_title"
        );
        this.detailDialogItems = this.qdroidItems;
        this.detailDialogContents = this.$t(
          "mainprojectandroid_qdroid_details"
        );
      }
      this.detailLoadingDialog = true;
    },
    closeFaqs() {
      this.faqDialog = false;
    },
    openFaqs(index) {
      this.faqDialog = true;
      if (index == 0) {
        this.faqDialogtitle = this.$t(
          "mainprojectandroid_wonderpod_faqs_title"
        );
        this.faqDialogList = this.localizedWonderpodsFaqList;
      } else if (index == 1) {
        this.faqDialogtitle = this.$t("mainprojectandroid_alldroid_faqs_title");
        this.faqDialogList = this.localizedAlldroidFaqList;
      } else if (index == 2) {
        this.faqDialogtitle = this.$t("mainprojectandroid_dotdroid_faqs_title");
        this.faqDialogList = this.localizedDotdroidFaqList;
      } else if (index == 3) {
        this.faqDialogtitle = this.$t("mainprojectandroid_qdroid_faqs_title");
        this.faqDialogList = this.localizedQdroidFaqList;
      }
    },
    scrollToCard(idx) {
      // 해당 idx 카드로 스크롤 이동
      const element = document.getElementById(`card-${idx}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center", // 카드를 하단으로 위치시킴
        });
      }
    },
    toggleQuestion(id) {
      if (this.expandedAnswer === id) {
        this.expandedAnswer = null;
      } else {
        this.expandedAnswer = id;
        this.$nextTick(() => {
          // Scroll to the expanded section after DOM update
          const expandedSection = this.$refs.expandedSection;
          if (expandedSection) {
            expandedSection.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        });
      }
    },
    formattedFaqsAnswer(answer) {
      // return this.multiLineText.replace(/\n/g, '<br/>');  // 줄바꿈을 <br/>로 변환
      return answer.replace(
        /<br\s*\/?>/g,
        '<div style="margin-bottom: 10px;"></div>'
      );
    },
  },
  computed: {
    formattedDetailsContent() {
      // return this.multiLineText.replace(/\n/g, '<br/>');  // 줄바꿈을 <br/>로 변환
      return this.detailDialogContents.replace(
        /<br>/g,
        '<div style="margin-bottom: 10px;"></div>'
      );
    },
    formattedFaqsContent() {
      // return this.multiLineText.replace(/\n/g, '<br/>');  // 줄바꿈을 <br/>로 변환
      return this.faqDialogContents.replace(
        /<br>/g,
        '<div style="margin-bottom: 10px;"></div>'
      );
    },
    localizedAndroidProjects() {
      return [
        {
          idx: 0,
          title: this.$t("mainprojectandroid_android_project_0_title"),
          subtitle: this.$t("mainprojectandroid_android_project_0_subtitle"),
          description: this.$t(
            "mainprojectandroid_android_project_0_description"
          ),
          button: this.$t("mainprojectandroid_android_project_download"),
          icon: require("../assets/new_wonderpod.png"),
        },
        {
          idx: 1,
          title: this.$t("mainprojectandroid_android_project_1_title"),
          subtitle: this.$t("mainprojectandroid_android_project_1_subtitle"),
          description: this.$t(
            "mainprojectandroid_android_project_1_description"
          ),
          button: this.$t("mainprojectandroid_android_project_download"),
          icon: require("../assets/alldroid_logo.png"),
        },
        {
          idx: 2,
          title: this.$t("mainprojectandroid_android_project_2_title"),
          subtitle: this.$t("mainprojectandroid_android_project_2_subtitle"),
          description: this.$t(
            "mainprojectandroid_android_project_2_description"
          ),
          button: this.$t("mainprojectandroid_android_project_download"),
          icon: require("../assets/dotdroid_logo.png"),
        },
        {
          idx: 3,
          title: this.$t("mainprojectandroid_android_project_3_title"),
          subtitle: this.$t("mainprojectandroid_android_project_3_subtitle"),
          description: this.$t(
            "mainprojectandroid_android_project_3_description"
          ),
          button: this.$t("mainprojectandroid_android_project_download"),
          icon: require("../assets/qdroid_logo.png"),
        },
      ];
    },
    localizedWonderpodsFaqList() {
      return [
        {
          id: 0,
          question: this.$t("mainprojectandroid_wonderpod_faqs_0_question"),
          answer: this.$t("mainprojectandroid_wonderpod_faqs_0_answer"),
        },
      ];
    },
    localizedAlldroidFaqList() {
      return [
        {
          id: 0,
          question: this.$t("mainprojectandroid_alldroid_faqs_0_question"),
          answer: this.$t("mainprojectandroid_alldroid_faqs_0_answer"),
        },
        {
          id: 1,
          question: this.$t("mainprojectandroid_alldroid_faqs_1_question"),
          answer: this.$t("mainprojectandroid_alldroid_faqs_1_answer"),
        },
        {
          id: 2,
          question: this.$t("mainprojectandroid_alldroid_faqs_2_question"),
          answer: this.$t("mainprojectandroid_alldroid_faqs_2_answer"),
        },
        {
          id: 3,
          question: this.$t("mainprojectandroid_alldroid_faqs_3_question"),
          answer: this.$t("mainprojectandroid_alldroid_faqs_3_answer"),
        },
        {
          id: 4,
          question: this.$t("mainprojectandroid_alldroid_faqs_4_question"),
          answer: this.$t("mainprojectandroid_alldroid_faqs_4_answer"),
        },
        {
          id: 5,
          question: this.$t("mainprojectandroid_alldroid_faqs_5_question"),
          answer: this.$t("mainprojectandroid_alldroid_faqs_5_answer"),
        },
      ];
    },
    localizedDotdroidFaqList() {
      return [
        {
          id: 0,
          question: this.$t("mainprojectandroid_dotdroid_faqs_0_question"),
          answer: this.$t("mainprojectandroid_dotdroid_faqs_0_answer"),
        },
      ];
    },
    localizedQdroidFaqList() {
      return [
        {
          id: 0,
          question: this.$t("mainprojectandroid_qdroid_faqs_0_question"),
          answer: this.$t("mainprojectandroid_qdroid_faqs_0_answer"),
        },
      ];
    },
  },
  mounted() {
    if (Object.keys(this.$route.params).length == 0) {
      window.scrollTo(0, 0);
    } else {
      // alert(JSON.stringify(this.$route.params.index));
      this.scrollToCard(this.$route.params.index);
    }
  },
  watch: {
    detailLoadingDialog(val) {
      if (!val) return;

      setTimeout(() => (this.detailLoadingDialog = false), 1000);
    },
  },
};
</script>

<style scoped>
.left-align {
  text-align: left;
  margin-left: 20px;
  /* 왼쪽 여백을 추가하여 더욱 왼쪽으로 정렬할 수 있습니다 */
}

.detail-content {
  max-height: 250px;
  overflow-y: auto;
  padding: 10px;
  text-align: left;
  margin-left: 20px;
}

.faq-content {
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  text-align: left;
  margin-left: 20px;
}

.wrap-btn {
  display: block;
  white-space: normal;
  text-align: center;
}

.sticky-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}
</style>
<template>
  <v-container fluid>
    <div class="d-flex mt-5 mb-5 justify-center">
      <h1>{{ $t("mainhome_container_news_title") }}</h1>
    </div>
    <v-row>
      <v-col cols="12">
        <v-overlay v-model="articlesLoadingDialog">
          <div v-if="articlesLoadingDialog">
            <v-progress-circular
              indeterminate
              color="gray"
            ></v-progress-circular>
          </div>
        </v-overlay>
        <v-data-table :items="articles" item-key="content" hide-default-footer>
          <template v-slot:item="props">
            <tr>
              <td>
                <div class="d-flex align-center">
                  <v-icon size="10">mdi-radiobox-blank</v-icon>
                  <a :href="props.item.url" target="_blank">
                    <h4>{{ props.item.content }}</h4>
                  </a>
                </div>
                <div
                  class="text-left"
                  style="margin-left: 21px; margin-top: 5px; font-size: 12px"
                >
                  <span>{{ props.item.writer }}</span>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div>
      <!-- display -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-8741998112060561"
        data-ad-slot="6939801101"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <!-- multiplex -->
      <!-- <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-format="autorelaxed"
        data-ad-client="ca-pub-8741998112060561"
        data-ad-slot="7138788252"
      ></ins> -->
    </div>
    <div class="d-flex mb-5 justify-center">
      <h1>{{ $t("mainhome_container_title") }}</h1>
      <!-- <h1>L2P's 프로젝트</h1> -->
    </div>
    <v-row>
      <v-col
        v-for="info in doneProjectInfo"
        :key="info.idx"
        :cols="info.flex"
        @click="img_click(info)"
      >
        <v-card :elevation="info.selected ? 12 : 2">
          <v-scroll-y-transition>
            <div v-if="!info.selected" class="text-h2 flex-grow-1 text-center">
              <v-img :aspect-ratio="16 / 16" :src="info.icon"></v-img>
            </div>
            <div v-else>
              <v-img
                :aspect-ratio="16 / 16"
                :src="info.icon"
                class="position-relative"
              ></v-img
              ><v-btn
                text
                class="position-absolute bottom-0"
                @click="goDetail(info.type, info.idx)"
                style="height: 40px; max-width: 100%; font-size: 10px"
              >
                {{ $t("mainhome_button_title") }}
                <!-- 자세히 보기 -->
              </v-btn>
            </div>
          </v-scroll-y-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    doneProjectInfo: [
      {
        idx: 0,
        selected: false,
        flex: 3,
        name: "Wonderpod",
        type: "android",
        icon: require("../assets/new_wonderpod.png"),
        content: "에어팟 전용 이어폰 유틸리티",
      },
      {
        idx: 1,
        selected: false,
        flex: 3,
        name: "Alldroid",
        type: "android",
        icon: require("../assets/alldroid_logo.png"),
        content: "범용 블루투스 이어폰 유틸리티",
      },
      {
        idx: 2,
        selected: false,
        flex: 3,
        name: "Dotdroid",
        type: "android",
        icon: require("../assets/dotdroid_logo.png"),
        content: "Xiaomi 블루투스 이어폰 유틸리티",
      },
      {
        idx: 3,
        selected: false,
        flex: 3,
        name: "Qdroid",
        type: "android",
        icon: require("../assets/qdroid_logo.png"),
        content: "QCY 블루투스 이어폰 유틸리티",
      },

      {
        idx: 4,
        selected: false,
        flex: 3,
        name: "Mbting",
        type: "web",
        icon: require("../assets/mbting_logo.png"),
        content: "재미로 MBTI 궁합보는 사이트",
      },
      {
        idx: 5,
        selected: false,
        flex: 3,
        name: "Lotto",
        type: "web",
        icon: require("../assets/lotto_logo.png"),
        content: "로또 시뮬레이션 사이트",
      },
      {
        idx: 6,
        selected: false,
        flex: 3,
        name: "Askme",
        type: "web",
        icon: require("../assets/askme_sample.png"),
        content: "나만의 게시판 사이트",
      },
    ],
    today: new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
    hours: new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[1]
      .split(":")[0],
    articles: [],
    articlesLoadingDialog: false,
  }),
  methods: {
    goDetail(type, idx) {
      var router = this.$router;

      if (type == "android") {
        router.push({
          name: "MainProjectAndroid",
          params: {
            index: idx,
          },
        });
      } else if (type == "web") {
        router.push({
          name: "MainProjectWeb",
          params: {
            index: idx,
          },
        });
      }
    },
    img_click(item) {
      // doneProjectInfo 배열 초기화 (selected 값을 false로 설정)
      this.doneProjectInfo.forEach((i) => {
        if (item.idx == i.idx) return;
        i.selected = false;
      });

      item.selected = !item.selected;
      // alert("id: " + item.idx + ", click: " + item.selected);
    },
    // 당일 db에 없으면 getArticles() 호출 후 setArticles()
    // 있으면 db 데이터 가져옴
    async getArticles(date, do_update) {
      await axios({
        method: "GET",
        url: "/api/articles/?date=" + date + "&do_update=" + do_update,
      })
        .then((response) => {
          this.articles = response.data;
          // alert(JSON.stringify(this.articles))
          this.articlesLoadingDialog = false;
        })
        .catch((e) => {
          console.log("axios getArticles GET error: ", e);
          this.articlesLoadingDialog = false;
        });
    },
    checkDateChangeSchedule() {
      setInterval(() => {
        var cur_today = new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];
        var cur_hours = new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[1]
          .split(":")[0];
        if (cur_hours != this.hours || cur_today != this.today) {
          this.getArticles(cur_today, 1);
          // console.log(
          //   "[schedule] cur_today: " +
          //     cur_today +
          //     ", today: " +
          //     this.today +
          //     ", cur_hour: " +
          //     cur_hours +
          //     ", hour: " +
          //     this.hours
          // );
          this.today = cur_today;
          this.hours = cur_hours;
        }
      }, 5 * 1000); // 5초 체크
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    this.articlesLoadingDialog = true;
    // console.log("[mounted] today: " + this.today + ", hour: " + this.hours);
    this.getArticles(this.today, 0);
    this.checkDateChangeSchedule();
  },
  created() {},
  computed: {
    cardStyle() {
      return {
        backgroundColor: this.hover ? "lightblue" : "lightgray", // hover 상태에 따른 색상 변경
        transition: "background-color 0.3s ease", // 부드러운 전환 효과
      };
    },
  },
  watch: {},
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
</style>

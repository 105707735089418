<template>
  <div>
    <v-container>
      <v-overlay v-model="loading">
        <div v-if="loading">
          <v-progress-circular indeterminate color="gray"></v-progress-circular>
        </div>
      </v-overlay>
      <div class="d-flex mb-5 justify-center">
        <h1>{{ $t("mainboard_container_title") }}</h1>
      </div>
      <v-card-title class="d-flex align-center pe-2">
        <v-text-field
          v-model="search"
          density="compact"
          :label="$t('mainboard_board_search')"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          flat
          hide-details
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="localizedHeader"
        :search="search"
        :sort-by="['date']"
        :items="paginatedItems"
        :items-per-page="itemsPerPage"
        mobile-breakpoint="0"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr
            style="text-align: center; cursor: pointer"
            @click="boardClick(item.id)"
          >
            <td width="5%">{{ calcNumber(boardList.indexOf(item)) }}</td>
            <td width="5%">
              <div v-if="item.content_type == 'etc'" style="text-align: left">
                <v-icon class="ms-1"> mdi-web</v-icon>
              </div>
              <v-img
                v-else
                rounded
                :src="convertContentTypeToIcon(item.content_type)"
                width="30px"
                heigth="30px"
              ></v-img>
            </td>
            <td v-if="user.email == item.email" width="45%">
              <b>{{ item.subject }}</b> ({{ item.reply_count }})
            </td>
            <td v-else width="45%">
              {{ item.subject }} ({{ item.reply_count }})
            </td>
            <td width="20%">
              {{ item.hitcount }}
            </td>
            <td width="20%">
              {{ convertTimestampToDate(item.create_timestamp) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-pagination
        class="mt-2"
        v-model="currentPage"
        :length="totalPages"
      ></v-pagination>
      <v-row>
        <v-col class="text-right">
          <v-btn @click="clickWriteBtn()">{{
            $t("mainboard_board_write")
          }}</v-btn>
        </v-col>
      </v-row>
      <div>
        <!-- display -->
        <ins
          class="adsbygoogle"
          style="display: block"
          data-ad-client="ca-pub-8741998112060561"
          data-ad-slot="6939801101"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <!-- multiplex -->
        <!-- <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-format="autorelaxed"
        data-ad-client="ca-pub-8741998112060561"
        data-ad-slot="7138788252"
      ></ins> -->
      </div>
    </v-container>
    <v-dialog v-model="writeFormDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>{{ $t("mainboard_board_write_title") }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeWriteForm()">mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :label="$t('mainboard_board_type')"
            :items="autoContentTypeItems"
            :rules="contentTypeRules"
            v-model="writeFormContentType"
            item-text="name"
            item-value="name"
            required
          >
            <!-- 선택된 항목 표시 -->
            <template v-slot:selection="{ item }">
              <v-chip style="background-color: transparent !important">
                <v-img
                  v-if="item.image_type == 'image'"
                  style="width: 30px; height: 30px"
                  :src="item.image"
                ></v-img>
                <div v-else style="text-align: left">
                  <v-icon>mdi-web</v-icon>
                </div>
                <p v-if="item.image_type == 'image'" class="ms-2">
                  {{ item.name }}
                </p>
                <p v-else class="ms-3">{{ item.name }}</p>
              </v-chip>
            </template>

            <!-- 드롭다운 목록 표시 -->
            <template v-slot:item="{ item }">
              <v-chip style="background-color: transparent !important">
                <v-img
                  v-if="item.image_type == 'image'"
                  style="width: 30px; height: 30px"
                  :src="item.image"
                ></v-img>
                <div v-else style="text-align: left">
                  <v-icon>mdi-web</v-icon>
                </div>
                <p v-if="item.image_type == 'image'" class="ms-2">
                  {{ item.name }}
                </p>
                <p v-else class="ms-3">{{ item.name }}</p>
              </v-chip>
            </template>
          </v-autocomplete>
          <v-text-field
            :label="$t('mainboard_board_subject')"
            :counter="32"
            :rules="subjectRules"
            v-model="writeFormSubject"
            variant="outlined"
            required
          ></v-text-field>
          <v-textarea
            :label="$t('mainboard_board_content')"
            :counter="2000"
            :rules="contentRules"
            v-model="writeFormContent"
            variant="outlined"
            required
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            border
            @click="writeLoadingDialog = !writeLoadingDialog"
            :disabled="writeLoadingDialog"
            :loading="writeLoadingDialog"
            >{{ $t("mainboard_board_btn_write") }}
          </v-btn>

          <v-btn border @click="closeWriteForm()"
            >{{ $t("mainboard_board_btn_close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    user: {},
    writeFormDialog: false,
    writeLoadingDialog: false,
    search: "",
    boardList: [],
    writeFormSubject: "",
    writeFormContentType: "",
    writeFormContent: "",
    writeFormPassword1: "",
    writeFormPassword2: "",
    writeFormPasswordInfo: "",
    writeFormData: {
      subject: "",
      content_type: "",
      content: "",
      email: "",
      hitcount: 0,
      create_timestamp: 0,
    },
    contentTypeRules: [(v) => !!v || "타입은 필수 입력사항 입니다."],
    subjectRules: [
      (v) => !!v || "제목은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 32) || "제목은 32자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    contentRules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 2000) || "내용은 2000자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    passwordrules: [
      (v) => !!v || "패스워드 형식이 올바르지 않습니다.",
      (v) => !(v && v.length >= 20) || "패스워드 형식이 올바르지 않습니다.",
    ],
    autoContentTypeItems: [
      {
        id: 1,
        name: "wonderpod",
        image_type: "image",
        image: require("../assets/wonderpod.png"),
      },
      {
        id: 2,
        name: "alldroid",
        image_type: "image",
        image: require("../assets/alldroid_logo.png"),
      },
      {
        id: 3,
        name: "dotdroid",
        image_type: "image",
        image: require("../assets/dotdroid_logo.png"),
      },
      {
        id: 4,
        name: "qdroid",
        image_type: "image",
        image: require("../assets/qdroid_logo.png"),
      },
      {
        id: 5,
        name: "mbting",
        image_type: "image",
        image: require("../assets/mbting_logo.jpeg"),
      },
      {
        id: 6,
        name: "lotto",
        image_type: "image",
        image: require("../assets/lotto_logo.png"),
      },
      {
        id: 7,
        name: "askme",
        image_type: "image",
        image: require("../assets/askme_sample.png"),
      },
      { id: 8, name: "etc", image_type: "none", image: "" },
    ],
    currentPage: 1,
    totalCount: 0,
    itemsPerPage: 5, // 한 페이지당 항목 수
    loading: false,
  }),
  methods: {
    async boardClick(id) {
      // await this.fetchData;
      this.$router.push({ name: "MainBoardPage", params: { id: id } });
    },
    calcNumber(index) {
      return this.totalCount - index;
    },
    convertContentTypeToIcon(content_type) {
      if (content_type == "wonderpod") {
        return require("../assets/wonderpod.png");
      } else if (content_type == "alldroid") {
        return require("../assets/alldroid_logo.png");
      } else if (content_type == "dotdroid") {
        return require("../assets/dotdroid_logo.png");
      } else if (content_type == "qdroid") {
        return require("../assets/qdroid_logo.png");
      } else if (content_type == "mbting") {
        return require("../assets/mbting_logo.jpeg");
      } else if (content_type == "lotto") {
        return require("../assets/lotto_logo.png");
      } else if (content_type == "askme") {
        return require("../assets/askme_sample.png");
      }
    },
    convertTimestampToDate(timestamp) {
      var tmp_date = new Date(timestamp);
      var tmp_year = tmp_date.getFullYear();
      var tmp_month = tmp_date.getMonth() + 1;
      var tmp_new_date;
      if (tmp_month < 10) tmp_month = "0" + tmp_month;
      var tmp_day = tmp_date.getDate();
      if (tmp_day < 10) tmp_day = "0" + tmp_day;
      tmp_new_date = tmp_year + "-" + tmp_month + "-" + tmp_day;
      // alert(tmp_new_date);
      return tmp_new_date;
    },
    async getBoardList() {
      await axios({
        method: "GET",
        url: "/api/mainboard",
      })
        .then((response) => {
          // alert(JSON.stringify(response.data));
          this.boardList = response.data.results;
          this.totalCount = response.data.count;
          // alert("getBoardList: " + JSON.stringify(this.boardList));
        })
        .catch((e) => {
          console.log("axios getBoardList GET error: ", e);
        });
    },
    clickWriteBtn() {
      if (this.user.is_logined == false) {
        alert(this.$t("mainboard_alert_btn_write"));
        return;
      }
      this.writeFormDialog = true;
    },
    async writeBoard() {
      if (
        this.writeFormContentType == "" ||
        this.writeFormSubject == "" ||
        this.writeFormContent == ""
      ) {
        alert(this.$t("mainboard_alert_warning_write"));
        this.writeLoadingDialog = false;
        return false;
      }

      this.writeFormData.subject = this.writeFormSubject;
      this.writeFormData.content_type = this.writeFormContentType;
      this.writeFormData.content = this.writeFormContent;
      this.writeFormData.email = this.user.email;
      this.writeFormData.hitcount = 0;
      this.writeFormData.create_timestamp = Date.now();

      await axios({
        method: "POST",
        url: "/api/mainboard/",
        data: this.writeFormData,
      })
        .then(() => {
          alert(this.$t("mainboard_alert_success_write"));
          this.getBoardList();
          this.clearWriteForm();
          this.closeWriteForm();
          window.location.reload();

          return true;
        })
        .catch((error) => {
          this.closeWriteForm();
          console.log("axios writeBoard POST error: ", error.reponse);
          alert(this.$t("mainboard_alert_fail_write"));
          return false;
        });
    },
    closeWriteForm() {
      this.writeLoadingDialog = false;
      this.writeFormDialog = false;
    },
    clearWriteForm() {
      this.writeForm = {};
      this.writeFormSubject = "";
      this.writeFormContentType = "";
      this.writeFormContent = "";
      // this.writeForm_Username = "";
      this.writeFormPassword1 = "";
      this.writeFormPassword2 = "";
      this.writeFormPasswordInfo = "";
    },
  },
  async created() {
    this.loading = true;
    this.user = this.getUserInfo;
    await this.getBoardList();
    this.loading = false;
  },
  async mounted() {
    window.scrollTo(0, 0);
    // await this.getBoardList();
  },
  watch: {
    writeLoadingDialog(val) {
      if (!val) return;

      setTimeout(
        () => this.writeBoard(),

        1000
      );
    },
    writeFormPassword1(val) {
      if (val != this.writeFormPassword2) {
        this.writeFormPasswordInfo = "패스워드가 일치하지 않습니다.";
      } else {
        this.writeFormPasswordInfo = "";
      }
    },
    writeFormPassword2(val) {
      if (val != this.writeFormPassword1) {
        this.writeFormPasswordInfo = "패스워드가 일치하지 않습니다.";
      } else {
        this.writeFormPasswordInfo = "";
      }
    },
    loading(val) {
      val &&
        setTimeout(() => {
          this.loading = false;
        }, 2000);
    },
  },
  computed: {
    ...mapGetters("loginStore", ["getUserInfo"]), // store.js의 getters를 연결해주는 helper
    totalPages() {
      return Math.ceil(this.boardList.length / this.itemsPerPage);
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.boardList.slice(start, end);
    },
    localizedHeader() {
      return [
        {
          text: "N",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("mainboard_board_type"),
          value: "content_type",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("mainboard_board_subject"),
          value: "subject",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("mainboard_board_viewer_count"),
          value: "hitcount",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("mainboard_board_date"),
          value: "create_date",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
};
</script>
<style></style>
